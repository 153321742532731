const privacyNotes = client => {
    return {
        index: () => client.get('/api/privacy-notes'),
        get: id => client.get(`/api/privacy-notes/${id}`),
        post: data => client.post('/api/privacy-notes', data),
        patch: (id, data) => client.post(`/api/privacy-notes/${id}/patch`, data),
        patchText: (id, text) => client.post(`/api/privacy-notes/${id}/patch/text`, { text }),
        delete: id => client.post(`/api/privacy-notes/${id}/delete`),
        questions: id => client.get(`/api/privacy-notes/${id}/questions`)
    };
};

export default privacyNotes;
