import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import Icon from '../widgets/Icon';
import Menu from '../widgets/Menu';
import { default as ConsultingServices } from '../components/ConsultingServices/Overview';
import Sheet from '../widgets/Sheet';
import useAuth from '../hooks/useAuth';
import useUI, { Breadcrumbs, Modals } from '../hooks/useUI';

import './scss/Dashboard.scss';

const Layout = () => {
	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const ui = useUI();

	const menuRef = useRef();

	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		setShowMenu(false);
	}, [location]);

	/* const handleConsultingServices = e => {
		setShowMenu(false);

		ui.show(<Sheet>
			<ConsultingServices />
		</Sheet>);
	}; */

	const handleOutsideMenuClick = e => {
		if (!menuRef.current || menuRef.current.contains(e.target)) {
			return;
		}

		setShowMenu(false);
	};

	const handleUserMenu = e => {
        ui.show(<Menu align='bottom' justify='left' trigger={e.currentTarget}>
            {/* <Menu.Item onClick={() => alert('account')}>
				<Icon name='file' />
				<span>Account</span>
			</Menu.Item>
			<Menu.Item onClick={() => alert('impostazioni')}>
				<Icon name='cog' />
				<span>Impostazioni</span>
			</Menu.Item>
			<Menu.Divider /> */}
			<Menu.Item onClick={() => navigate('/logout')}>
				<Icon name='door-open' />
				<span>Esci</span>
			</Menu.Item>
        </Menu>);
    };

	return <>
		<div data-layout='dashboard'>
			<header>
				<button onClick={() => setShowMenu(true)}>
					<svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16">
						<path d="M1 2.75A.75.75 0 0 1 1.75 2h12.5a.75.75 0 0 1 0 1.5H1.75A.75.75 0 0 1 1 2.75Zm0 5A.75.75 0 0 1 1.75 7h12.5a.75.75 0 0 1 0 1.5H1.75A.75.75 0 0 1 1 7.75ZM1.75 12h12.5a.75.75 0 0 1 0 1.5H1.75a.75.75 0 0 1 0-1.5Z"></path>
					</svg>
				</button>
				<NavLink role='button' to='/' className='logo'>
					<img src='/assets/logo.svg' width='96' height='30' alt='Lisalex' />
				</NavLink>
				<Breadcrumbs />
				<nav className='actions'>
					<button onClick={handleUserMenu}>
						<span>{auth.user.username}</span>
						<Icon name='user-circle' />
						{/* <img src='/assets/icons/user.png' width='48' height='48' alt={auth.user.username} /> */}
					</button>
				</nav>
			</header>
			<main>
				<Outlet />
			</main>
			<footer>
                <p className='copy'>&copy; {new Date().getFullYear()} Wichee - Tutti i diritti riservati</p>
            </footer>
			<Transition 
				appear={true}
				in={showMenu}
				timeout={{ appear: 0, enter: 250, exit: 250 }}
				unmountOnExit>
				{state => <>
					<div id='menu' className={state} onClick={handleOutsideMenuClick}>
						<div ref={menuRef}>
							<header>
								<span className='logo'>
									<img src='/assets/logo.svg' width='96' height='30' alt='Lisalex' />
								</span>
								<button onClick={() => setShowMenu(false)}>
									<svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-x">
										<path d="M3.72 3.72a.75.75 0 0 1 1.06 0L8 6.94l3.22-3.22a.749.749 0 0 1 1.275.326.749.749 0 0 1-.215.734L9.06 8l3.22 3.22a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L8 9.06l-3.22 3.22a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L6.94 8 3.72 4.78a.75.75 0 0 1 0-1.06Z"></path>
									</svg>
								</button>
							</header>
							<nav>
								<NavLink role='button' end to='/'>
									<Icon name='home-lg'/>
									<span>Home</span>
								</NavLink>
								{/* <NavLink disabled role='button' end to='/inbox'>
									<Icon name='message' />
									<span>Inbox</span>
									<div>0</div>
								</NavLink> */}
								<NavLink role='button' end to='/customers'>
									<Icon name='wallet' />
									<span>Clienti</span>
								</NavLink>
								{auth.user.acl.roles.includes('admin-agenzia') && <>
									<div className='divider'/>
									<span className='header'>Privacy</span>
									<NavLink role='button' end to='/privacy-notes'>
										<Icon name='file' />
										<span>Informative</span>
									</NavLink>
									<div className='divider'/>
									<span className='header'>Allegati IVASS</span>
									<NavLink role='button' end to='/companies'>
										<Icon name='briefcase' />
										<span>Compagnie</span>
									</NavLink>
									<NavLink role='button' end to='/consulting-services'>
										<Icon name='bullseye-pointer' />
										<span>Consulenze</span>
									</NavLink>
									<NavLink role='button' end to='/fees'>
										<Icon name='sack-dollar' />
										<span>Remunerazioni</span>
									</NavLink>
									<NavLink role='button' end to='/brokers'>
										<Icon name='book-user' />
										<span>Produttori</span>
									</NavLink>
								</>}
							</nav>
						</div>
					</div>
				</>}
			</Transition>
		</div>
		<Modals />
	</>
	
};

export default Layout;
