import React from 'react';

import Dropdown from './Dropdown';
import './scss/Menu.scss';

const Caption = ({ text }) => {
    return <li className='caption'>
        <span>{text}</span>
    </li>
};

const Divider = () => {
    return <li className='divider'/>
};

const Item = ({ children, onClick }) => {
    return <li className='item' onClick={onClick}>{children}</li>
};

const MenuElement = ({ children }) => {
    return <ul className='menu'>{children}</ul>
};

class Menu extends React.Component {
    static Caption = Caption;
    static Divider = Divider;
    static Item = Item;
    
    constructor(props) {
        super(props);
    }

    render() {
        return <Dropdown
            hash={this.props.hash}
            align={this.props.align}
            justify={this.props.justify}
            trigger={this.props.trigger}>
            <MenuElement>
                {this.props.children}
            </MenuElement>
        </Dropdown>
    }
}

export default Menu;
