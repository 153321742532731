import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import ConsultingService from '../../models/ConsultingService';
import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Panel from '../../widgets/Panel';
import Prompt from '../../widgets/Prompt';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const Patch = ({ _service, id, onChanged, parentDialog }) => {
    const api = useApi();
    const ui = useUI();

    const [service, setService] = useState(_service);

    const handleDelete = e => {
        const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='trash'
				title="Conferma rimozione"
				message="Confermi di volere rimuovere questo elemento?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
                        prompt.dismiss();
                        
						api.consultingServices.delete(id).then(res => {
                            onChanged();
                            parentDialog.dismiss();
                        });
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
    };

    const handleSubmit = e => {
        api.consultingServices.patch(id, service).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Consulenza aggiornata'
                    /* positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}} */
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    const title = 'Modifica consulenza';

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 1.4rem' }}>
                <Form.Control label='Etichetta' errors={api.errors?.title}>
                    <Form.InputLabel>
                        <Icon name='briefcase' />
                        <input
                            onChange={e => setService({...service, title: e.target.value})}
                            placeholder='Esempio: Consulenza obbligatoria'
                            value={service.title} />
                    </Form.InputLabel>
                </Form.Control>
                <hr/>
                <Form.Control label='Nota / Riferimento normativo' errors={api.errors?.text}>
                    <Form.InputLabel>
                        <Icon name='pen' />
                        <textarea
                            onChange={e => setService({...service, text: e.target.value})}
                            placeholder='Esempio: "Fornisce una consulenza obbligatoria e gratuita ai sensi dell’art. 121-septies, Cap"'
                            rows={8}
                            value={service.text} />
                    </Form.InputLabel>
                    <Form.TextLength text={service.text} max={500} />
                    <Form.Tip text='Inserisci il testo da mostrare nei documenti' />
                </Form.Control>
            </Form>
        </main>
        <footer>
            <button className='negative-link' onClick={handleDelete}>
                <span>elimina</span>
                <Icon name='trash' />
            </button>
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} opaque={!service} />
    </>
};

export default Patch;
