import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Error from '../../widgets/Error';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import PatchText from './PatchText';
import Prompt from '../../widgets/Prompt';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const Import = ({ _broker, onChanged, parentDialog }) => {
    const title = 'Copia allegato';

    const api = useApi();
    const ui = useUI();

    const [broker, setBroker] = useState();
    const [brokers, setBrokers] = useState([]);
    const [attachment, setAttachment] = useState();

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setAttachment();
    }, [broker]);

    const fetch = () => {
        api.attachments.prepareImport().then(res => {
            setBrokers(res.data.brokers.filter(broker => broker.id !== _broker.id));
        });
    };

    const _import = data => {
        api.attachments.import(data).then(res => {
            onChanged();
            parentDialog.dismiss();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message={res.message}
                    positive={{
                        label: 'Vai al contenuto',
                        onClick: prompt => {
                            prompt.dismiss();

                            ui.show(<Dialog width='80rem' height='100vh'>
                                <PatchText id={res.data.id} onChanged={onChanged} />
                            </Dialog>);
                        }
                    }} />
            </Dialog>);
        });
    };

    const handleSubmit = () => {
        if (!broker || !attachment) {
            return;
        }

        const data = {
            broker_id: _broker.id,
            attachment_id: attachment.id
        };

        const exists = _broker.attachments.find(tmpl => tmpl.type_id === attachment.type_id);

        if (exists) {
            const prompt = <Dialog>
                <Prompt
                    icon={<Icon name='signature' />}
                    color='trash'
                    title="Conferma copia"
                    message="Questo tipo di allegato è già stato assegnato al produttore. Desideri sovrascriverlo?"
                    positive={{
                        label: "Sovrascrivi",
                        onClick: prompt => {
                            prompt.dismiss();
                            _import(data);
                        }
                    }}
                    neutral={{
                        label: "Annulla",
                        onClick: prompt => {
                            prompt.dismiss();
                        }
                    }}
                />
            </Dialog>

            ui.show(prompt);
            return;
        }

        _import(data);
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            {!api.error && <>
                <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 2rem 8rem 2rem' }}>
                    <Form.Control label='Produttore' errors={api.errors?.broker_id}>
                        {brokers.filter(broker => broker.attachments.length > 0).length > 0 
                            ? <>
                                <Form.InputLabel>
                                    <Icon name='book-user' />
                                    <select
                                        autoFocus
                                        onChange={e => setBroker(brokers.find(broker => broker.id == e.target.value))}
                                        value={broker?.id || 0}>
                                        <option disabled value={0}>Seleziona produttore</option>
                                        {brokers.map((broker, index) => {
                                            return <option disabled={!broker.attachments.length} key={index} value={broker.id}>{broker.code} - {broker.title}</option>
                                        })}
                                    </select>
                                </Form.InputLabel>
                                <Form.Tip text='Seleziona tra i produttori disponibili' />
                            </>
                            : <>
                                <Alert mode='error'>
                                    <Icon name='triangle-exclamation'/>
                                    <span><mark>Produttori non disponibili</mark><br/>Devi configurare almeno un produttore prima di continuare.</span>
                                </Alert>
                            </>
                        }
                    </Form.Control>
                    <hr/>
                    {broker && <>
                        <Form.Control label='Allegati'>
                            {broker.attachments.map((tmpl, index) => {
                                return (
                                    <label key={index} className='check'>
                                        <input
                                            onChange={() => setAttachment(tmpl)}
                                            checked={attachment?.id === tmpl.id}
                                            name='attachments'
                                            type='radio' />
                                        <span className='mark' />
                                        <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                            <Icon name='file' style={{ color: 'var(--colorText2)' }} />
                                            <span style={{ color: 'var(--colorText)', flex: 1 }}>{tmpl.title}</span>
                                        </span>
                                    </label>
                                );
                            })}
                            <Alert mode='error' show={!broker.attachments.length}>
                                <Icon name='triangle-exclamation' />
                                <span><mark>Allegati non disponibili</mark><br/>Questo produttore non presenta allegati</span>
                            </Alert>
                        </Form.Control>
                    </>}
                </Form>
            </>}
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </main>
        <footer style={{ background: 'transparent' }}>
            <button style={{ width: '100%' }} disabled={!broker || !attachment} className='accented' onClick={() => handleSubmit()}>
                <span>Copia da altro produttore</span>
                <Icon name='file-import' />
            </button>
        </footer>
        <Loading show={api.loading} opaque={!brokers.length} />
    </>
};

export default Import;
