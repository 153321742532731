import React from 'react';
import './scss/Alert.scss';

const Alert = ({ mode = 'info', children, show = true }) => {
    if (!show) {
        return null;
    }
    return <div className={`alert ${mode}`}>{children}</div>
};

export default Alert;
