import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Cards, { Card } from '../../widgets/Cards';
import Dialog from '../../widgets/Dialog';
import Error from '../../widgets/Error';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Patch from './Patch';
import Post from './Post';
import useApi from '../../hooks/useApi';
import useBrowse, { BrowseProvider } from '../../hooks/useBrowse';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';

import './scss/Index.scss';

const List = () => {
    const title = 'Remunerazioni';

    const api = useApi();
    const browse = useBrowse();
    const date = useDate();
    const ui = useUI();

    const [paginator, setPaginator] = useState([]);
    const [rows, setRows] = useState([]);

    const fetch = () => {
        setPaginator([]);
        setRows([]);

        api.fees.index().then(res => {
            setPaginator(res.data.paginator);
            setRows(res.data.rows);
        });
    };

    useEffect(() => {
        ui.setBreadcrumbs([
            { label: 'Home', path: '/' },
            { label: title },
        ])
    }, []);

    useEffect(() => {
        fetch();
    }, [browse.params]);

    const handleChanged = () => {
        fetch();
    };

    const handlePatch = fee => {
        ui.show(<Dialog width='40rem'>
            <Patch _fee={fee} onChanged={handleChanged} />
        </Dialog>);
    };

    const handlePost = e => {
        ui.show(<Dialog width='40rem'>
            <Post onChanged={handleChanged} />
        </Dialog>);
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div data-component='feesIndex'>
            <header>
                <h1>{title}</h1>
            </header>
            <section>
                {!api.loading && !api.error && <h2>Tutte<span>{rows.length} elementi</span></h2>}
                <div style={{ position: 'relative', height: '50vh' }}>
                    {!api.loading && !api.error && <>
                        <Cards>
                            <div role='button' className='placeholder' onClick={handlePost}>
                                <span>Aggiungi<Icon name='plus'/></span>
                            </div>
                            {rows.map((row, index) =>
                                <Card key={index} onClick={() => handlePatch(row)}>
                                    <header>
                                        <Icon name='sack-dollar'/>
                                    </header>
                                    <footer>
                                        <h3>{row.title}</h3>
                                        <div className='info'>
                                            <div>
                                                <span>Modifica</span>
                                                <span>{date.locale(row.updated_at)}</span>
                                            </div>
                                            {/* <div>
                                                <span>Identificativo</span>
                                                <span>{row.id}</span>
                                            </div> */}
                                        </div>
                                    </footer>
                                </Card>
                            )}
                        </Cards>
                    </>}
                    <Loading show={api.loading} />
                    {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
                </div>
            </section>
        </div>
    </>
};

const Index = () => {
    return <>
        <BrowseProvider>
            <List />
        </BrowseProvider>
    </>
};

export default Index;
