import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import Alert from '../../../widgets/Alert';
import AttachmentSendingDetail from './AttachmentSendingDetail';
import Dialog from '../../../widgets/Dialog';
import Icon from '../../../widgets/Icon';
import Loading from '../../../widgets/Loading';
import Post from '../../Envelopes/Attachments/Post';
import useApi from '../../../hooks/useApi';
import useDate from '../../../hooks/useDate';
import useUI from '../../../hooks/useUI';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Envelopes = ({ customer, parentDialog }) => {
    const api = useApi();
    const date = useDate();
    const ui = useUI();

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        api.customers._logs.attachments(customer.id).then(res => {
            setLogs(res.data);
        })
    }, []);

    const handleDetail = log => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <AttachmentSendingDetail log={log} />
        </Dialog>);
    };

    return <>
        <header>
            <span>Allegati inviati</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <div data-component='customerEnvelopes'>
                {logs.length > 0 && <>
                    <div className='envelope'>
                        <span className='date'>Data e ora</span>
                        <span className='title'>Tipo</span>
                        <span className='channel'>Produttore</span>
                    </div>
                    {logs.map((log, index) =>
                        <div key={index} role='button' className='envelope' onClick={() => handleDetail(log)}>
                            <span className='date'>{date.locale(log.created_at)}</span>
                            <span className='title'>{log.document.data?.template?.title || 'Modello sconosciuto'}</span>
                            <span className='channel'>{log.document.data?.broker?.code || 'XX'}</span>
                        </div>
                    )}
                </>}
                <Alert mode='info' show={!api.loading && !logs.length}>
                    <Icon name='circle-info'/>
                    <span>Non ci sono dati da mostrare</span>
                </Alert>
            </div>
        </main>
        <Loading show={api.loading && !api.error} opaque={!logs.length} />
    </>
};

const Attachments = ({ customer, onChanged, widget = false }) => {
    const ui = useUI();
    
    const handleDetail = log => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <AttachmentSendingDetail log={log} />
        </Dialog>);
    };

    const handleEnvelopePost = (e, customer) => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <Post customer={customer} onChanged={onChanged} />
        </Dialog>);
    };

    const handleEnvelopes = e => {
        ui.show(<Dialog width='48rem'>
            <Envelopes customer={customer} />
        </Dialog>);
    };

    return <>
        <header>
            <span className='title'>Allegati IVASS</span>
            <span role='button' className='count' onClick={handleEnvelopes}>Visualizza tutti</span>
        </header>
        <div className='attachmentsList'>
            {customer.attachments.map((attachment, index) =>
                <div key={index} role='button' className='attachment' onClick={e => handleDetail(attachment)}>
                    <div className='title'>{attachment.document.data?.template?.title || 'Modello sconosciuto'}</div>
                </div>
            )}
            <Alert show={!customer.attachments.length} mode='accented' >
                <span>Gli allegati IVASS inviati al cliente verranno visualizzati qui</span>
            </Alert>
        </div>
        <button className='accented-2' onClick={e => handleEnvelopePost(e, customer)}>
            <span>Invia allegati IVASS</span>
            <Icon name='paper-plane' />
        </button>
        {/* {widget && <footer className='lisalexPowered'>
            <span>Powered by</span><img src='/assets/logo.svg' alt='Lisalex' />
        </footer>} */}
    </>
}

export default Attachments;
