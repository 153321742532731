import React from 'react'

const useDate = () => {
	const today = () => {
		return new Date().getTime();
	};

	const yesterday = () => {
		const date = new Date();
		date.setDate(date.getDate() - 1);

		return date.getTime();
	};

	const thisWeek = () => {
		const date = new Date();

		const d = date.getDay();
		const diff = date.getDate() - d + (d == 0 ? -6 : 1);

		return {
			start: new Date(date.setDate(diff)).getTime(),
			end: new Date().getTime()
		};
	};

	const thisMonth = () => {
		const date = new Date();

		return {
			start: new Date(date.getFullYear(), date.getMonth(), 1).getTime(),
			end: new Date().getTime()
		};
	};

	const thisYear = () => {
		const year = new Date().getFullYear();

		return {
			start: new Date(year, 0, 1).getTime(),
			end: new Date().getTime()
		};
	};

	const locale = date => {
		const _date = new Date(date);
		//_date.setTime(_date.getTime() - (_date.getTimezoneOffset() *60 *1000));

		return _date.toLocaleString('it', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		});
	};

	return {
		today,
		yesterday,
		thisWeek,
		thisMonth,
		thisYear,
		locale
	};
};

export default useDate;
