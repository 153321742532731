import React from 'react';

import Icon from './Icon';
import './scss/Error.scss';

const Error = ({ text, onReload }) => {
    return <>
        <div className='errorFull'>
            <Icon name='triangle-exclamation' />
            <span className='title'>Attenzione</span>
            <span className='text'>{text}</span>
            <button onClick={() => onReload()}>
                <span>Riprova</span>
                <Icon name='rotate' />
            </button>
        </div>
    </>
};

export default Error;
