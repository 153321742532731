import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async'

import Attachments from './Attachments';
import Error from '../../widgets/Error';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Panel from '../../widgets/Panel';
import useApi from '../../hooks/useApi';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';

import './scss/Overview.scss';

const Overview = ({ id, parentDialog }) => {
    const api = useApi();
    const date = useDate();
    const ui = useUI();
    
    const [broker, setBroker] = useState();

    useEffect(() => {
        fetch();
    }, []);

    const handleAttachmentsChanged = () => {
        fetch();
    };

    const fetch = () => {
        api.brokers.get(id).then(res => {
            setBroker(res.data);
        });
    };

    const title = 'Produttore';

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='chevron-left'/>
                </button>
            </div>
            <span>{title}</span>
        </header>
        <main>
            {broker && <Panel>
                <Panel.Main>
                    <div data-component='brokerOverview'>
                        <header>
                            <div className='top'>
                                {broker.status_broker.value == 0 && <Icon name='briefcase' />}
                                {broker.status_broker.value == 2 && <Icon name='user' />}
                                {/* <button disabled className='accented-2' onClick={handlePatch}>
                                    <span>Modifica</span>
                                    <Icon name='chevron-right' />
                                </button> */}
                            </div>
                            <div className='name'>
                                <span>{broker.title}</span>
                            </div>
                        </header>
                        <div className='divider' />
                        <span className="header">Dati anagrafici</span>
                        <div className='attributes'>
                            {broker.status_broker.value == 0 &&  <>
                                <div>
                                    <span>Ragione sociale</span>
                                    <span>{broker.attributables.CNAM}</span>
                                </div>
                                <div>
                                    <span>Partita IVA</span>
                                    <span>{broker.attributables.CREG}</span>
                                </div>
                            </>}
                            {broker.status_broker.value == 2 &&  <>
                                <div>
                                    <span>Nome</span>
                                    <span>{broker.attributables.NAME}</span>
                                </div>
                                <div>
                                    <span>Cognome</span>
                                    <span>{broker.attributables.SURN}</span>
                                </div>
                                <div></div>
                                <div>
                                    <span>Codice fiscale</span>
                                    <span>{broker.attributables.NINO}</span>
                                </div>
                            </>}
                        </div>
                        <div className='divider' />
                        <span className="header">Altro</span>
                        <div className='attributes'>
                            <div>
                                <span>Modifica</span>
                                <span>{date.locale(broker.last_update)}</span>
                            </div>
                            {/* <div>
                                <span>Identificativo</span>
                                <span>{broker.id}</span>
                            </div> */}
                        </div>
                    </div>
                </Panel.Main>
                <Panel.Divider />
                <Panel.Side>
                    <Attachments onChanged={handleAttachmentsChanged} broker={broker} />
                </Panel.Side>
            </Panel>}
            <Loading show={api.loading} opaque={!broker} />
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </main>
    </>
};

export default Overview;
