import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import useUI from '../../hooks/useUI';
import Panel from '../../widgets/Panel';
import Patch from './Patch';
import Widget from './Widget/Widget';
import useDate from '../../hooks/useDate';

import './scss/Overview.scss';

const Overview = ({ _customer, onChanged, parentDialog }) => {
    const title = 'Cliente';

    const date = useDate();
    const ui = useUI();
    
    const [customer, setCustomer] = useState(_customer);

    const handlePatch = () => {
        ui.show(<Dialog width='48rem' height='60rem'>
            <Patch id={customer.id} />
        </Dialog>);
    };

    const correspondence = customer?.correspondences.find(correspondence => correspondence.status_correspondence.value == 0);
    const addressbook = customer?.address_books.find(addressbook => addressbook.status_addressbook.value == 0);

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='chevron-left'/>
                </button>
            </div>
            <span>{title}</span>
        </header>
        <main>
            {customer && <Panel>
                <Panel.Main>
                    <div data-component='customerOverview'>
                        <header>
                            <div className='top'>
                                {customer.status_registry.value == 0 && <Icon name='user' />}
                                {customer.status_registry.value == 1 && <Icon name='briefcase' />}
                                {/* <button className='accented-2' onClick={handlePatch}>
                                    <span>Modifica</span>
                                    <Icon name='chevron-right' />
                                </button> */}
                            </div>
                            <div className='name'>
                                <span>{customer.title}</span>
                            </div>
                            {correspondence && <>
                                <div className='location'>
                                    <Icon name='location-dot'/>{correspondence.attributables.ADDR} - {correspondence.geo_location.COUNCIL}, {correspondence.geo_location.PRVCODE}
                                </div>
                            </>}
                        </header>
                        <div className='divider' />
                        <span className="header">Dati anagrafici</span>
                        <div className='attributes'>
                            {customer.status_registry.value == 0 &&  <>
                                <div>
                                    <span>Nome</span>
                                    <span>{customer.attributables.NAME}</span>
                                </div>
                                <div>
                                    <span>Cognome</span>
                                    <span>{customer.attributables.SURN}</span>
                                </div>
                                <div>
                                    <span>Data di nascita</span>
                                    <span>{customer.attributables.DOB || 'N/D'}</span>
                                </div>
                                <div></div>
                            </>}
                            {customer.status_registry.value == 1 &&  <>
                                <div>
                                    <span>Ragione sociale</span>
                                    <span>{customer.attributables.CNAM}</span>
                                </div>
                                <div>
                                    <span>Partita IVA</span>
                                    <span>{customer.attributables.CREG}</span>
                                </div>
                            </>}
                            <div>
                                <span>Codice fiscale</span>
                                <span>{customer.attributables.NINO}</span>
                            </div>
                        </div>
                        <div className='divider' />
                        <span className="header">Informazioni di contatto</span>
                        <div className='attributes'>
                            <div>
                                <span>Indirizzo e-mail</span>
                                <span>{addressbook ? addressbook.attributables.MAIL : 'n/d'}</span>
                            </div>
                            <div>
                                <span>Cellulare</span>
                                <span>{addressbook ? addressbook.attributables.CELL : 'n/d'}</span>
                            </div>
                            <div>
                                <span>Telefono</span>
                                <span>{addressbook ? addressbook.attributables.PHON : 'n/d'}</span>
                            </div>
                        </div>
                        <div className='divider' />
                        <span className="header">Altro</span>
                        <div className='attributes'>
                            <div>
                                <span>Modifica</span>
                                <span>{date.locale(customer.last_update)}</span>
                            </div>
                            {/* <div>
                                <span>Identificativo</span>
                                <span>{customer.id}</span>
                            </div> */}
                        </div>
                    </div>
                </Panel.Main>
                <Panel.Divider />
                <Panel.Side>
                    <Widget _customer={customer} />
                </Panel.Side>
            </Panel>}
        </main>
    </>
};

export default Overview;
