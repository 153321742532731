import React from 'react';

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import Import from '../Attachments/Import';
import Patch from '../Attachments/Patch';
import Post from '../Attachments/Post';
import useUI from '../../hooks/useUI';

import './scss/Attachments.scss';

const Attachment = ({ attachment, onClick }) => {
    return <>
        <div className='attachment' role='button' onClick={onClick}>
            <Icon name='paperclip'/>
            <div>
                <span className='title'>{attachment.title}</span>
            </div>
        </div>
    </>
};

const Attachments = ({ broker, onChanged }) => {
    const ui = useUI();

    const handleAttachmentImport = () => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <Import _broker={broker} onChanged={onChanged} />
        </Dialog>);
    };

    const handleAttachmentPatch = attachment => {
        ui.show(<Dialog width='36rem'>
            <Patch id={attachment.id} onChanged={onChanged} />
        </Dialog>);
    };

    const handleAttachmentPost = () => {
        ui.show(<Dialog width='36rem'>
            <Post broker={broker} onChanged={onChanged} />
        </Dialog>);
    };

    return <>
        <header>
            <span className='title'>Allegati IVASS</span>
            {broker.attachments.length > 0 && 
                <span className='count'>{broker.attachments.length}</span>
            }
        </header>
        <div data-component='brokerAttachments'>
            <Alert mode='error' show={!broker.attachments.length}>
                <Icon name='triangle-exclamation negative' />
                <span><mark>Nessun allegato</mark><br/>I produttori senza allegati non saranno disponibili</span>
            </Alert>
            {broker.attachments.map((attachment, index) =>
                <Attachment
                    key={index}
                    onClick={() => handleAttachmentPatch(attachment)}
                    attachment={attachment} />
            )}
        </div>
        <button disabled={broker.attachments.length == 4} className='accented' onClick={() => handleAttachmentPost(broker)}>
            <span>Aggiungi allegato</span>
            <Icon name='file' />
        </button>
        <button className='accented-2' onClick={() => handleAttachmentImport()}>
            <span>Copia da altro produttore</span>
            <Icon name='file-import' />
        </button>
    </>
}

export default Attachments;
