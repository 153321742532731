import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import QRCode from 'react-qr-code';

import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import Prompt from '../../widgets/Prompt';
import SignaturePreview from './SignaturePreview';
import useApi from '../../hooks/useApi';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';
import Loading from '../../widgets/Loading';

//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DocumentCover = ({ document, hash }) => {
    const api = useApi();
    const date = useDate();
    const ui = useUI();

    const [numPages, setNumPages] = useState(0);
    const [show, setShow] = useState(false);

    const handleDownload = document => {
        const link = window.document.createElement('a');
        link.href = document.content;
        link.setAttribute('download', `${document.data?.template?.title || 'Modello sconosciuto'}.pdf`);
        link.click();
        link.remove();
    };

    const handleLoadSuccess = document => {
        setNumPages(document.numPages);
    };

    const handlePreview = document => {
        setShow(show => !show);
    };

    const handleSignaturePreview = document => {
        ui.show(<Dialog width='32rem' height='36rem'>
            <SignaturePreview document={document} />
        </Dialog>);
    };

    const handleSign = document => {
		const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='neutral'
				title="Conferma firma"
				message="Confermi di volere firmare i documenti?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
						api.envelopes.sign(hash, document.id).then(res => {
                            ui.show(<Dialog>
                                <Prompt
                                    icon={<Icon name='check' />}
                                    color='positive'
                                    title='Fatto'
                                    message='Documenti firmati con successo'
                                    neutral={{ label: 'Chiudi', onClick: prompt => {
                                        window.location.reload(false);
                                    }}} />
                            </Dialog>);
                        });
						prompt.dismiss();
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
	}

    const loading = (
        <div className='doc-loading' />
    );

    const documentWidth = ui.windowSize.width > 768 ? 210 : ui.windowSize.width - 40;

    return <>
        <div className='document'>
            <header>
                <span className='title'>{document.data?.template?.title || 'Modello sconosciuto'}</span>
                <span className='pages'><Icon name='file'/>PDF - {numPages} pagin{numPages != 1 ? 'e' : 'a'}</span>
            </header>
            <div className='cover'>
                <Document file={document.content} onLoadSuccess={handleLoadSuccess} loading={loading}>
                    <Page pageNumber={1} width={documentWidth} />
                </Document>
                <button onClick={() => handlePreview(document)}>
                    <span>Visualizza</span><Icon name='arrow-up-right-from-square'/>
                </button>
            </div>
            <footer>
                {document.template.type_id == 1 && !document.sign && <>
                    <div className='actions'>
                        <button
                            className='accented'
                            disabled={api.loading}
                            data-loading={api.loading}
                            onClick={() => handleSign(document)}>
                                <span>Firma</span>
                                <Icon name='signature'/>
                        </button>
                    </div>
                </>}
                {document.template.type_id == 1 && document.sign && <>
                    <div className='sign'>
                        <div className='data'>
                            <div>
                                <span>Firma</span>
                                <button onClick={() => handleSignaturePreview(document)}>
                                    <QRCode /*fgColor='#991b1d'*/ size={48} value={JSON.stringify({
                                        checksum: document.sign.document_sum,
                                        data: document.sign.data,
                                        timestamp: document.sign.created_at,
                                    })} />
                                </button>
                            </div>
                            <div>
                                <span>Data e ora</span>
                                <span>{date.locale(document.sign.created_at)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='actions' style={{ marginTop: '2rem' }}>
                        <button
                            className='accented'
                            disabled={api.loading}
                            data-loading={api.loading}
                            onClick={() => handleDownload(document)}>
                                <span>Scarica</span>
                                <Icon name='inbox-in'/>
                        </button>
                    </div>
                </>}
                {[2,3,4,5].includes(document.template.type_id) && <>
                    <div className='actions' style={{ marginTop: '2rem' }}>
                        <button
                            className='accented'
                            disabled={api.loading}
                            data-loading={api.loading}
                            onClick={() => handleDownload(document)}>
                                <span>Scarica</span>
                                <Icon name='inbox-in'/>
                        </button>
                    </div>
                </>}
            </footer>
        </div>
        {show && <>
            <div className='preview'>
                <header>
                    <span className='pages'><Icon name='file'/>PDF - {numPages} pagin{numPages != 1 ? 'e' : 'a'}</span>
                    <span className='title'>{document.data?.template?.title || 'Modello sconosciuto'}</span>
                    <button onClick={() => setShow(show => !show)}>
                        <span>Chiudi</span>
                        <Icon name='times'/>
                    </button>
                </header>
                <main>
                    <Document file={document.content} onLoadSuccess={handleLoadSuccess} loading={loading}>
                        {Array(numPages).fill(null).map((el, index) => {
                            const width = ui.windowSize.width > 768 ? 800 : ui.windowSize.width - 40;
                            return (
                                <Page key={index} pageNumber={index + 1} width={width} />
                            );
                        })}
                    </Document>
                </main>
            </div>
        </>}
        <Loading show={api.loading} />
    </>
};

export default DocumentCover;
