import React from 'react';
import './scss/Loading.scss';

const Loading = ({ opaque = false, show = false }) => {
    if (!show) {
        return null;
    }
    
    return <> 
        <div className='loading' data-opaque={opaque} >
            <img src='/assets/logo.svg' width='96' height='30' alt='Lisalex' />
            <span />
        </div>
    </>
};

export default Loading;
