const privacyNotesQuestions = client => {
    return {
        get: id => client.get(`/api/privacy-notes/questions/${id}`),
        post: data => client.post('/api/privacy-notes/questions', data),
        patch: (id, data) => client.post(`/api/privacy-notes/questions/${id}/patch`, data),
        delete: id => client.post(`/api/privacy-notes/questions/${id}/delete`)
    };
};

export default privacyNotesQuestions;
