import React from 'react';
import { Outlet } from 'react-router';

import { Modals } from '../hooks/useUI';
import './scss/Auth.scss';

const Layout = () => {
	return <>
		<div data-layout='auth'>
			<main>
				<header>
					<img src='/assets/logo.svg' alt='Lisalex'></img>
				</header>
				<Outlet />
			</main>
			<footer>
				<p className='copy'>&copy; {new Date().getFullYear()}, Wichee</p>
			</footer>
		</div>
		<Modals />
	</>
};

export default Layout;
