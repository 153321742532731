import React from 'react';
import './scss/Prompt.scss';
const Prompt = ({
	color,
	icon,
	title,
	message,
	positive,
	positives,
	negative,
	neutral,
	neutrals,
	parentDialog
}) => {
	return <>
		<div className='prompt'>
			<header>
				<div className={color}>{icon}</div>
				<span dangerouslySetInnerHTML={{__html: title}} />
			</header>
			<p>{message}</p>
			<div className='actions'>
				{positive && <button onClick={e => positive.onClick(parentDialog)} className={positive?.class ? positive.class : 'positive'}>{positive.label}</button>}
				{positives && positives.map((item, index)=> {
					const handleClick = e => {
						item?.onClick instanceof Function ? item.onClick(parentDialog) : parentDialog.dismiss();
					};

					return <button key={index} onClick={handleClick} className={item?.class ? item.class : 'positive'}>
						{item?.icon && <i className={item.icon} />}
						{item.label}
					</button>
				})}
				{negative && <button onClick={e => negative.onClick(parentDialog)} className={negative?.class ? negative.class : 'negative'}>{negative.label}</button>}
				{neutral && <button onClick={e => neutral.onClick(parentDialog)} className={neutral?.class ? neutral.class : 'neutral'}>{neutral.label}</button>}
				{neutrals && neutrals.map((item, index)=> {
					return <button key={index} onClick={e => item?.onClick instanceof Function ? item.onClick(parentDialog) : parentDialog.dismiss()} className={item?.class ? item.class : 'neutral'}>{item.label}</button>
				})}
			</div>
		</div>
	</>
};

export default Prompt;
