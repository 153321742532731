import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Prompt from '../../widgets/Prompt';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const PatchNote = ({ _note, onChanged, parentDialog }) => {
    const title = 'Nessuna consulenza (nota)';

    const api = useApi();
    const ui = useUI();

    const [note, setNote] = useState(_note);

    const handleSubmit = e => {
        api.consultingServices.patchNote(note).then(res => {
            onChanged();
            
            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Nota aggiornata'
                    /* positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}} */
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 1.4rem' }}>
                <Form.Control label='Etichetta'>
                    <Form.InputLabel>
                        <Icon name='briefcase' />
                        <input
                            disabled
                            value='Nessuna consulenza' />
                    </Form.InputLabel>
                </Form.Control>
                <hr/>
                <Form.Control label='Nota / Riferimento normativo' errors={api.errors?.note}>
                    <Form.InputLabel>
                        <Icon name='pen' />
                        <textarea
                            onChange={e => setNote(e.target.value)}
                            placeholder='Esempio: "Non fornisce una consulenza ai sensi dell’art. 119-ter, d.lgs 209/2005 (“Codice delle assicurazioni private”) o “Cap”)"'
                            rows={8}
                            value={note} />
                    </Form.InputLabel>
                    <Form.TextLength text={note} max={500} />
                    <Form.Tip text='Inserisci il testo da mostrare nei documenti (Lasciare vuoto per rimuovere la nota).' />
                </Form.Control>
            </Form>
        </main>
        <footer>
            &nbsp;
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} opaque={!note} />
    </>
};

export default PatchNote;
