import React from 'react';
import { Helmet } from 'react-helmet-async';

import './scss/Boot.scss';

const Layout = () => {
	const title = 'Avvio di Lisalex';

	return <>
		<Helmet>
			<title>{title}</title>
		</Helmet>
		<div data-layout='boot'>
			<main>
				<header>
					<img src='/assets/logo.svg' alt='Lisalex'></img>
				</header>
				<h1>Avvio</h1>
			</main>
			<footer>
				<p className='copy'>&copy; {new Date().getFullYear()}, Wichee</p>
			</footer>
		</div>
	</>
};

export default Layout;
