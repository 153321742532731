import React, { useEffect, useState } from 'react';
import { useLocation, useRoutes } from 'react-router';

import useApi from './hooks/useApi';
import useAuth, { AuthRoute, ForbiddenRoute, GuestRoute } from './hooks/useAuth';

import { default as LayoutAuth } from './layouts/Auth';
import { default as LayoutBoot } from './layouts/Boot';
import { default as LayoutDashboard } from './layouts/Dashboard';
import { default as LayoutWidget } from './layouts/Widget';

import Home from './components/Home';
import Login from './components/Auth/Login';
import Logout from './components/Auth/Logout';

import { default as Brokers } from './components/Brokers/Index';
import { default as Companies } from './components/Companies/Index';
import { default as ConsultingServices } from './components/ConsultingServices/Index';
import { default as Customers } from './components/Customers/Index';
import { default as Fees } from './components/Fees/Index';
import { default as PrivacyNotes } from './components/PrivacyNotes/Index';

import Overview from './components/Envelopes/Overview';
import Placeholder from './components/Placeholder';
import Widget from './components/Customers/Widget/Widget';

import './App.scss';

const routes = [
	{
		element: <AuthRoute />,
		children: [
			{
				element: <LayoutDashboard />,
				children: [
					{ path: '/', element: <Home />, index: true },
					{ path: '/brokers', element: <Brokers /> },
					{ path: '/companies', element: <Companies /> },
					{ path: '/consulting-services', element: <ConsultingServices /> },
					{ path: '/customers', element:  <ForbiddenRoute except='resources.registries.index'><Customers /></ForbiddenRoute> },
					{ path: '/fees', element: <Fees />},
					{ path: '/privacy-notes', element: <PrivacyNotes />},
					{ path: '*', element: <Placeholder title='pagina non trovata'/> }
				]
			},
			{ 
				element: <LayoutAuth />,
				children: [
					{ path: '/logout', element: <Logout /> },
				]
			},
		]
	},
	{
		element: <GuestRoute />,
		children: [
			{ 
				element: <LayoutAuth />,
				children: [
					{ path: '/login', element: <Login /> },
				]
			},
			{ path: '/guest', element: <Placeholder title='pagina guest' /> },
		]
	},
	{ 
		element: <LayoutWidget />,
		children: [
			{ path: '/widget/:id', element: <Widget /> }
		]
	},
	{ path: '/:hash', element: <Overview /> },
	{ path: '*', element: <Placeholder title='pagina non trovata' /> }
];

const Routes = () => {
	return useRoutes(routes);
};

const App = () => {
	const api = useApi();
	const auth = useAuth();
	const location = useLocation();

	const regex = /^[0-9a-zA-Z]{20}[0-9]{5}/;
	const isDoc = regex.exec(location.pathname.substring(1)) !== null;
	
	const [booted, setBooted] = useState(isDoc ? true : false);

	useEffect(() => {
		if (booted) return;
		
		api.auth.autoLogin()
			.then(res => auth.refresh(res))
			.finally(() => setBooted(true));
	}, []);

	if (!booted) {
		return <LayoutBoot />
	}

	return <Routes />
};


export default App;
