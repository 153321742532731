import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Panel from '../../widgets/Panel';
import PatchText from './PatchText';
import Prompt from '../../widgets/Prompt';
import Template from '../../models/Template';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const Post = ({ broker, onChanged, parentDialog }) => {
    const api = useApi();
    const ui = useUI();
    
    const [template, setTemplate] = useState({ ...Template, lw3_broker_id: broker.id });
    
    const handleSubmit = e => {
        api.attachments.post(template).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Allegato aggiunto'
                    positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                        
                        ui.show(<Dialog width='76rem' height='100vh'>
                            <PatchText id={res.data.id} onChanged={onChanged} />
                        </Dialog>);
                    }}}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };
    
    const title = 'Aggiungi allegato';
    
    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Panel>
                <Panel.Main>
                    {broker.available_types.length > 0 && <>
                        <Form onSubmit={handleSubmit}>
                            <Form.Control label='Tipo' errors={api.errors?.type_id}>
                                <Form.InputLabel>
                                    <Icon name='paperclip' />
                                    <select
                                        autoFocus
                                        onChange={e => setTemplate({...template, type_id: e.target.value})}
                                        value={template.type_id || 0}>
                                        <option disabled value={0}>Seleziona tipo</option>
                                        {broker.available_types.map((type, index) => {
                                            return <option key={index} value={type.id}>{type.title}</option>
                                        })}
                                    </select>
                                </Form.InputLabel>
                                <Form.Tip text='Seleziona tra i tipi di allegato disponibili' />
                            </Form.Control>
                            <hr/>
                            <Form.Control label='Pubblicato' errors={api.errors?.published}>
                                <Form.ButtonGroup>
                                    <Form.ButtonGroup.Item
                                        onChange={e => setTemplate({...template, published: true})}
                                        name='published'
                                        label='Sì'
                                        positive
                                        selected={template.published}
                                        value={true} />
                                    <Form.ButtonGroup.Item
                                        onChange={e => setTemplate({...template, published: false})}
                                        label='No'
                                        name='published'
                                        negative
                                        selected={!template.published}
                                        value={false}
                                    />
                                </Form.ButtonGroup>
                                <Form.Tip text="Un modello non pubblicato non è disponibile per l'invio ai clienti" />
                            </Form.Control>
                        </Form>
                    </>}
                    <Alert mode='error' show={!broker.available_types.length}>
                        <Icon name='triangle-exclamation negative' />
                        <span>Questo produttore presenta già tutti i tipi di allegato disponibili</span>
                    </Alert>
                </Panel.Main>
            </Panel>
        </main>
        {broker.available_types.length > 0 && <>
            <footer>
                <>&nbsp;</>
                <button className='accented' onClick={handleSubmit}>
                    <span>Aggiungi</span>
                </button>
            </footer>
        </>}
        <Loading show={api.loading} />
    </>
};

export default Post;
