import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet-async";

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Prompt from '../../widgets/Prompt';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';


const Patch = ({ _question, onChanged, parentDialog }) => {
    const title = 'Modifica quesito';
    
    const api = useApi();
    const ui = useUI();
    
    const [question, setQuestion] = useState(_question);

    const handleDelete = e => {
        const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='trash'
				title="Conferma rimozione"
				message="Confermi di volere rimuovere questo elemento?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
                        prompt.dismiss();

						api.privacyNotesQuestions.delete(question.id).then(res => {
                            onChanged();
                            parentDialog.dismiss();
                        });
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
    };

    const handleLetterChange = e => {
        const letter = e.target.value.replace(/[^a-z]/gi, '');
        setQuestion({...question, letter: letter.toUpperCase()});
    };

    const handleSubmit = e => {
        api.privacyNotesQuestions.patch(question.id, question).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Quesito aggiornato'
                    positive={{ label: 'Torna al contenuto', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}}
                    neutral={{ label: 'Continua le modifiche', onClick: prompt => prompt.dismiss() }} />
            </Dialog>);
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e=> parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem' }}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ width: '6rem' }}>
                        <Form.Control label='Lettera' errors={api.errors?.letter}>
                            <Form.InputLabel>
                                <input
                                    maxLength={1}
                                    onChange={handleLetterChange}
                                    placeholder='Es. A'
                                    value={question.letter} />
                            </Form.InputLabel>
                        </Form.Control>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Control label='Etichetta' errors={api.errors?.name}>
                            <Form.InputLabel>
                                <Icon name='pen' />
                                <input
                                    maxLength={255}
                                    onChange={e => setQuestion({...question, name: e.target.value})}
                                    placeholder='Esempio: "Consenso privacy"'
                                    value={question.name} />
                            </Form.InputLabel>
                        </Form.Control>
                    </div>
                </div>
                <hr/>
                <header>
                    <span className='title'>Modalità di prestazione<br/> del consenso</span>
                </header>
                <Alert>
                    <Icon name='circle-info' />
                    <span>
                        <mark>Obbligatoria</mark>: Il consenso deve essere prestato prima di procedere alla firma<br/>
                    </span>
                </Alert>
                <Form.Control label='' errors={api.errors?.is_mandatory}>
                    <label className="check">
                        <input
                            onChange={e => setQuestion({...question, is_mandatory: e.currentTarget.checked})}
                            checked={question.is_mandatory}
                            type='checkbox' />
                        <span className='mark' />
                        <span className='label'>Obbligatoria</span>
                    </label>
                </Form.Control>
            </Form>
        </main>
        <footer>
            <button className='negative-link' onClick={handleDelete}>
                <span>elimina</span>
                <Icon name='trash' />
            </button>
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} />
    </>
};

export default Patch;
