import React, { useState } from 'react';

const useMath = () => {
    const interpolate = (val, inputRange, outputRange) => {
		return (val - inputRange[0]) * (outputRange[1] - outputRange[0]) / (inputRange[1] - inputRange[0]) + outputRange[0];
	};

    return {
        interpolate
    }
};

export default useMath;
