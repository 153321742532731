const Template = {
    id: 0,
    type_id: 0,
    lw3_broker_id: 0,
    lw3_privacy_note_id: 0,
    title: '',
    text: null,
    published: true,
    questions: []
};

export default Template;
