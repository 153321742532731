import React, { cloneElement, createRef } from 'react';
import { Transition } from 'react-transition-group';
import useUI from '../hooks/useUI';
import './scss/Sheet.scss';

const Sheet = ({ hash, exiting, children }) => {
	const ui = useUI();
	const ref = createRef();

	const dismiss = () => {
		ui.dismiss(hash);
	};

	return <>
		<Transition
			appear={true}
			in={!exiting}
			timeout={{
				appear: 0,
				enter: 250,
				exit: 250
			}}
			unmountOnExit>
			{state => {
				const clazzes = [
					'sheetBackdrop',
					state
				];

				return <>
					<div className={clazzes.join(' ')} role='dialog'>
						<div ref={ref}>
							{React.Children.map(children, child => {
								return cloneElement(child, {
									parentDialog: {
										dismiss,
                                        hash
									}
								})
							})}
						</div>
					</div>
				</>
			}}
		</Transition>
	</>
};

export default Sheet;
