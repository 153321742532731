import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Attachments from './Attachments';
import Consent from './Consent';
import Error from '../../../widgets/Error';
import Loading from '../../../widgets/Loading';
import Panel from '../../../widgets/Panel';
import useApi from '../../../hooks/useApi';

import './Widget.scss';

const Widget = ({ _customer }) => {
    const title = 'Lisalex widget';

    const api = useApi();
    const { id, token } = useParams();
    const [searchParams] = useSearchParams();

    const [customer, setCustomer] = useState();

    useEffect(() => {
        fetch();
    }, [id]);

    const fetch = () => {
        api.customers.get(_customer ? _customer.id : id).then(res => {
            setCustomer(res.data);
        });
    };

    const handleChanged = () => {
        fetch();
    };

    if (_customer && customer) {
        return <>
            <Consent customer={customer} onChanged={handleChanged} />
            <div className='divider' />
            <Attachments customer={customer} onChanged={handleChanged} />
            <Loading show={api.loading} />
        </>
    }

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Panel>
            <Panel.Side>
                {!api.error && <div className='lisalexPowered'>
                    <img src='/assets/logo.svg' width={100} height={30} alt='Lisalex' />
                </div>}
                {customer && <>
                    <Consent widget={true} customer={customer} onChanged={handleChanged} />
                    <div className='divider' />
                    <Attachments widget={true} customer={customer} onChanged={handleChanged} />
                </>}
                <Loading show={api.loading} opaque={!customer} />
                {api.error && <Error text={api.error.message} onReload={fetch} />}
            </Panel.Side>
        </Panel>
    </>
};

export default Widget;
