const customers = client => {
    return {
        index: queryString => client.get(`/api/customers${queryString}`),
        get: id => client.get(`/api/customers/${id}`),
        patch: (id, data) => client.post(`/api/customers/${id}/patch`, data),
        _logs: {
            attachments: id => client.get(`/api/customers/${id}/logs/attachments`),
            privacyNotes: id => client.get(`/api/customers/${id}/logs/privacy-notes`)
        }
    };
};

export default customers;
