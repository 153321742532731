import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { HelmetProvider } from 'react-helmet-async';
import { UIProvider } from './hooks/useUI';

import App from './App';

const root = createRoot(document.getElementById('root'));

const app = <>
    <BrowserRouter>
        <HelmetProvider>
            <UIProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </UIProvider>
        </HelmetProvider>
    </BrowserRouter>
</>

root.render(app);
