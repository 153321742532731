import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import Company from '../../models/Company';
import Dialog from '../../widgets/Dialog';
import Error from '../../widgets/Error';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Prompt from '../../widgets/Prompt';
import useUI from '../../hooks/useUI';
import useApi from '../../hooks/useApi';

const Patch = ({ _company, onChanged, parentDialog }) => {
    const title = 'Configura compagnia';

    const api = useApi();
    const ui = useUI();
    
    const [company, setCompany] = useState(_company);

    const handleSubmit = e => {
        api.companies.patch(company.id, company).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message={res.message}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e=> parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            {!api.error &&
                <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 1.4rem' }}>
                    <Form.Control label='Compagnia'>
                        <Form.InputLabel>
                            <Icon name='briefcase' />
                            <input disabled value={`${company.code} - ${company.title}`}/>
                        </Form.InputLabel>
                    </Form.Control>
                    <hr/>
                    <Form.Control label='Rapporto di intermediazione' errors={api.errors?.brokerage_relationship}>
                        <Form.InputLabel>
                            <Icon name='pen' />
                            <textarea
                                onChange={e => setCompany({...company, config: {...company.config, brokerage_relationship: e.target.value}})}
                                placeholder='Esempio: "Il contratto è distribuito dall’intermediario (nel ruolo di proponente) in collaborazione orizzontale, ai sensi dell’articolo 22, comma 10, d.l. 179/2012, convertito nella legge 221/2012"'
                                rows={8}
                                maxLength={500}
                                value={company.config?.brokerage_relationship} />
                        </Form.InputLabel>
                        <Form.TextLength text={company.config?.brokerage_relationship} max={500} />
                        <Form.Tip text='Lasciare vuoto per rimuovere la configurazione per questa compagnia' />
                    </Form.Control>
                </Form>
            }
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </main>
        <footer>
            &nbsp;
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma</span>
            </button>
        </footer>
        <Loading show={api.loading} />
    </>
};

export default Patch;
