const auth = client => {
    return {
        autoLogin: () => client.get('/api/autologin'),
        login: (username, password) => client.post('/api/login', { username, password }),
        logout: () => client.post('/api/logout'),
        profile: () => client.get('/api/profile')
    };
};

export default auth;
