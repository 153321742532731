import React from 'react';
import { Helmet } from 'react-helmet-async';

const Placeholder = ({ title }) => {
	return <>
		<Helmet>
			<title>{title || 'Placeholder'}</title>
		</Helmet>
		<h1>{title || 'Placeholder'}</h1>
	</>
};

export default Placeholder;
