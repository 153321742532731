import React from 'react';
import './scss/Panel.scss';

const Divider = ({ children }) => {
    return <div className='divider'  />
};

const Main = ({ children, style }) => {
    return <div className='main' style={style}>{children}</div>
};

const Side = ({ children, style }) => {
    return <div className='side' style={style}>{children}</div>
};

class Panel extends React.Component {
    static Divider = Divider;
    static Main = Main;
    static Side = Side;
    
    constructor(props) {
        super(props);
    }

    render() {
        return <div className='panel' style={this.props.style}>{this.props.children}</div>
    }
}

export default Panel;
