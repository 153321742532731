import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './scss/Table.scss';

const Body = ({ children }) => <tbody>{children}</tbody>

const Cell = ({ children, expanded }) => {
    return <td className={expanded ? 'expanded' : undefined}>{children}</td>
}

const Footer = ({ browse, paginator }) => {
    const location = useLocation();

    if (!paginator) {
        return null;
    }

    return <>
        <tfoot>
            <tr>
                <td colSpan={10}>
                    <div>
                        {paginator.links.map((link, index) => {
                            if (!link.url) {
                                if (index === 0) {
                                    return <span key={index}>Precedente</span>
                                }
                                if (index === paginator.links.length -1)  {
                                    return <span key={index}>Successivo</span>
                                }

                                return <span key={index}>{link.label}</span>
                            }

                            let url = location.pathname + link.url.substring(link.url.indexOf('?'), link.url.length);
                            url = url.replaceAll('%5B', '[');
                            url = url.replaceAll('%5D', ']');

                            const remove = [
                                'sort%5BbyAttribute%5D',
                                'dir%5BbyAttribute%5D',
                                'byRegistry%5Btitle%5D',
                                'byAttribute%5Btitle%5D'
                            ];

                            remove.map(s => url = url.replaceAll(s, ''));

                            /* const _url = new URL(location.pathname + link.url.substring(link.url.indexOf('?'), link.url.length));
                            const params = new URLSearchParams(_url.search);
                            params.delete('sort[byAttribute]');
                            params.delete('dir[byAttribute]');
                            params.delete('byRegistry[title]');
                            params.delete('byAttribute[title]'); */
                            
                            const className = ({isActive}) => (isActive && link.label == browse.get('page', 1)) ? 'active' : '';

                            if (index === 0) {
                                return <NavLink className={className} role='button' key={index} end to={url}>Precedente</NavLink>
                            }
                            if (index === paginator.links.length -1)  {
                                return <NavLink className={className} role='button' key={index} end to={url}>Successivo</NavLink>
                            }

                            return <NavLink className={className} role='button' key={index} end to={url}>{link.label}</NavLink>
                        })}
                    </div>
                </td>
            </tr>
        </tfoot>
    </>
};

const Head = ({ browse, columns }) => {
    const handleHeadingClick = e => {
		const th = e.target;
		const thCol = th.getAttribute('data-col');

		// Read sorting from search params
		let sortCol = browse.get('sortCol');
		let sortDir = browse.get('sortDir');

		if (sortCol === thCol) {
			if (!sortDir) {
				sortDir = 'desc';
			}
			else if (sortDir === 'desc') {
				sortDir = 'asc';
			}
			else if (sortDir === 'asc') {
				sortCol = null;
				sortDir = null;
			}
		}
		else {
			sortCol = thCol;
			sortDir = 'desc';
		}

        sortCol ? browse.put('sortCol', sortCol, false) : browse.remove('sortCol', false)
		sortDir ? browse.put('sortDir', sortDir, false) : browse.remove('sortDir', false)
		browse.remove('page', false);
        browse.submit();
	}

    return <>
        <thead>
            <tr>
                {columns.map((column, index) => {
                    return (
                        <th key={index}
                            role={column.sortCol ? 'button' : undefined}
                            className={column?.expanded ? 'expanded' : undefined}
                            data-col={column.sortCol}
                            data-dir={browse.get('sortCol') == column?.sortCol ? browse.get('sortDir') : undefined}
                            onClick={column?.sortCol && handleHeadingClick}
							>{column.label}
                        </th>
                    );
                })}
            </tr>
        </thead>
    </>
};

const Header = ({ paginator }) => {
    if (!paginator) {
        return null;
    }

    return <>
        <div className='paginator'>
            <span>risultati {paginator.from} di {paginator.to} - {paginator.total} totali, pagina {paginator.current_page} di {paginator.last_page}</span>
        </div>
    </>
}

const Row = ({ children, even, onClick }) => {
    return <tr className={even ? 'even' : undefined} onClick={onClick} role='button'>{children}</tr>
}

const TableElement = ({ browse, children }) => {
    const ref = useRef();

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        const table = ref.current;
        const ths = table.querySelectorAll('th[data-col]')

		Array.from(ths).map(th => {
			//th.removeAttribute('data-dir')

			const cols = table.querySelectorAll(`th:nth-child(${th.cellIndex + 1}), td:nth-child(${th.cellIndex + 1})`)

			for (let i = 0; i < cols.length; i++) {
				cols[i].classList.remove('sorted');
			}

			th.addEventListener('mouseover', e => {
				const cols = table.querySelectorAll(`th:nth-child(${th.cellIndex + 1}), tr:not(.index) > td:nth-child(${th.cellIndex + 1})`)

				for (let i = 0; i < cols.length; i++) {
					cols[i].classList.add('hovered');
				}
			})

			th.addEventListener('mouseout', e => {
				const cols = table.querySelectorAll(`th.hovered, td.hovered`)
				
				for (let i = 0; i < cols.length; i++) {
					cols[i].classList.remove('hovered');
				}
			})
		});

        const sortCol = browse.get('sortCol');
		const sortDir = browse.get('sortDir');

		Array.from(ths).map(th => {
			if (th.getAttribute('data-col') == sortCol && th.getAttribute('data-dir') == sortDir) {
				const cols = table.querySelectorAll(`th:nth-child(${th.cellIndex + 1}), td:nth-child(${th.cellIndex + 1})`)

				for (let i = 0; i < cols.length; i++) {
					cols[i].classList.add('sorted');
				}
			}
		})
    }, [ref]);

    return <>
        <table ref={ref} className='table'>{children}</table>
    </>
};

export class Table extends React.Component {
    static Body = Body;
    static Cell = Cell;
    static Footer = Footer;
    static Head = Head;
    static Header = Header;
    static Row = Row;
    static Table = Table;

    constructor(props) {
        super(props);
    }
    
    render() {
        const loader = this.props.loader;
        
        if (loader == null) {
            return <TableElement>{this.props.children}</TableElement>
        }
        
        if (loader.loading) {
            return <p>loading...</p>
        }

        if (loader.error) {
            return <p>error {JSON.stringify(loader.error)}</p>
        }

        return <TableElement browse={this.props.browse}>{this.props.children}</TableElement>
    }
}

export default Table;
