import React, { useEffect } from 'react';
import { Navigate } from 'react-router';

import useAuth from '../hooks/useAuth';
import useUI from '../hooks/useUI';

const Home = () => {
    const auth = useAuth();
    const ui = useUI();

    useEffect(() => {
        ui.setBreadcrumbs([]);
    }, []);

    return <Navigate to='/customers' replace />
};

export default Home;
