import { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Dialog from '../widgets/Dialog';
import Icon from '../widgets/Icon';
import Login from '../components/Auth/Login';
import Prompt from '../widgets/Prompt';
import useUI from './useUI';

import attachments from '../api/attachments';
import auth from '../api/auth';
import brokers from '../api/brokers';
import companies from '../api/companies';
import consultingServices from '../api/consultingServices';
import customers from '../api/customers';
import envelopes from '../api/envelopes';
import fees from '../api/fees';
import privacyNotes from '../api/privacyNotes';
import privacyNotesQuestions from '../api/privacyNotesQuestions';

const useApi = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const ui = useUI();

    const [error, setError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

     /* useEffect(() => {
        error && console.log('useApi.error', error);
    }, [error]); */

    const token = searchParams.get('token');

    const client = axios.create({
        // baseURL: 'http://127.0.0.1:3334',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...(token != null && { 'Authorization': `Bearer ${token}` })
        },
        withCredentials: true,
        // withXSRFToken: true,
        timeout: 20000
    });

    client.interceptors.request.use(
        config => {
            setError(null);
            setErrors(null);
            setLoading(true);
            
            const delayed = false;

            if (delayed) {
                return new Promise(resolve => setTimeout(() => resolve(config), 1000));
            }

            return config;
        }
    );

    client.interceptors.response.use(
        response => {
            setLoading(false);
            return response.data;
        },
        error => {
            //setError(error);
            setLoading(false);

            const statusCode = error.response ? error.response.status : null;

            if (statusCode === null) {
                return networkError();
            }

            return processError(statusCode, error);
        }
    );

    const networkError = () => {
        setError({
            code: null,
            message: 'Si è verificato un errore di rete'
        });

        return Promise.reject(error);
    };

    const processError = (code, err) => {
        const errors = [
            { code: 401, message: 'Errore di autenticazione' },
            { code: 403, message: 'Azione negata' },
            { code: 404, message: 'Non trovato' },
            { code: 422, message: 'I dati forniti non sono validi' },
            { code: 500, message: 'Errore interno del server' },
            { code: 503, message: 'Servizio non disponibile' }
        ];

        const error = errors.find(_err => _err.code === code);

        if (error) {
            error.message = err.response.data.message;
        }

        setError(error || {
            code: null,
            message: 'Si è verificato un errore sconosciuto'
        });

        if (error.code === 401) {
            const requestURL = new URL(err.request.responseURL);

            if (!location.pathname.startsWith('/widget/')) {
                if (!['/api/login', '/api/profile', '/api/autologin'].includes(requestURL.pathname)) {
                    ui.show(<Dialog width='32rem'>
                        <Login />
                    </Dialog>);
                    
                    return Promise.reject(err);
                }
            }
        }

        if (error.code === 422) {
            setErrors(err.response.data.errors);

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='triangle-exclamation' />}
                    color='negative'
                    title='Attenzione'
                    message='Controlla i dati inseriti e riprova'
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                    }}} />
            </Dialog>);
            
            return Promise.reject(err);
        }

        if (!err.response.data?.silent) {
            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='triangle-exclamation' />}
                    color='negative'
                    title='Errore'
                    message={error.message}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                    }}} />
            </Dialog>);
        }

        return Promise.reject(err);
    };

    return {
        error,
        errors,
        loading,
        attachments: attachments(client),
        auth: auth(client),
        brokers: brokers(client),
        companies: companies(client),
        consultingServices: consultingServices(client),
        customers: customers(client),
        envelopes: envelopes(client),
        fees: fees(client),
        privacyNotes: privacyNotes(client),
        privacyNotesQuestions: privacyNotesQuestions(client)
    }
};

export default useApi;
