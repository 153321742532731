const useSequence = () => {
    const charSlot = (source) => {
        const chars = Array.from(Array(26)).map((e, i) => i + 65);
        const alphabet = chars.map(x => String.fromCharCode(x));
        const letter = alphabet.find(x => !source.includes(x));
        
        return letter || '';
    };

    return {
        charSlot
    };
};

export default useSequence;
