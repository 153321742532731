import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Panel from '../../widgets/Panel';
import PatchText from './PatchText';
import Prompt from '../../widgets/Prompt';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const Patch = ({ _privacyNote, onChanged, parentDialog }) => {
    const title = 'Modifica informativa';

    const api = useApi();
    const ui = useUI();

    const [privacyNote, setPrivacyNote] = useState(_privacyNote);

    const handleDelete = e => {
        const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='trash'
				title="Conferma rimozione"
				message="Confermi di volere rimuovere questo elemento?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
                        prompt.dismiss();

						api.privacyNotes.delete(privacyNote.id).then(res => {
                            onChanged();
                            parentDialog.dismiss();
                        });
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
    };
    
    const handleTextPatch = () => {
        ui.show(<Dialog width='114rem' height='100vh'>
            <PatchText id={privacyNote.id} onChanged={onChanged} />
        </Dialog>);
    };

    const handleSubmit = e => {
        api.privacyNotes.patch(privacyNote.id, privacyNote).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Informativa aggiornata'
                    positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Panel>
                <Panel.Main>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control label='Titolo' errors={api.errors?.name}>
                            <Form.InputLabel>
                                <Icon name='file' />
                                <input
                                    maxLength={120}
                                    onChange={e => setPrivacyNote({...privacyNote, name: e.target.value})}
                                    placeholder='Esempio: Informativa privacy'
                                    value={privacyNote.name} />
                            </Form.InputLabel>
                        </Form.Control>
                        <hr/>
                        {/* <Form.Control label='Tag' errors={api.errors?.tag}>
                            <Form.InputLabel>
                                <Icon name='tag' />
                                <input
                                    maxLength={120}
                                    onChange={e => setPrivacyNote({...privacyNote, tag: e.target.value})}
                                    placeholder='Aggiungi tag'
                                    value={privacyNote.tag} />
                            </Form.InputLabel>
                        </Form.Control> */}
                        <hr/>
                        <Form.Control label='Pubblicato' errors={api.errors?.is_active}>
                            <Form.ButtonGroup>
                                <Form.ButtonGroup.Item
                                    onChange={e => setPrivacyNote({...privacyNote, is_active: true})}
                                    name='is_active'
                                    label='Sì'
                                    positive
                                    selected={privacyNote.is_active}
                                    value={true} />
                                <Form.ButtonGroup.Item
                                    onChange={e => setPrivacyNote({...privacyNote, is_active: false})}
                                    label='No'
                                    name='is_active'
                                    negative
                                    selected={!privacyNote.is_active}
                                    value={false}
                                />
                            </Form.ButtonGroup>
                            <Form.Tip text="Un modello non pubblicato non è disponibile per l'invio ai clienti" />
                        </Form.Control>
                        <hr/>
                        <Form.Control label='Nota di versione' errors={api.errors?.version}>
                            <Form.InputLabel>
                                <Icon name='code-branch' />
                                <textarea
                                    maxLength={240}
                                    onChange={e => setPrivacyNote({...privacyNote, version: e.target.value})}
                                    placeholder='Esempio: "versione iniziale"'
                                    rows={3}
                                    value={privacyNote.version} />
                            </Form.InputLabel>
                            <Form.TextLength text={privacyNote.version} max={240} />
                            <Form.Tip text='Indicare la versione del modello' />
                        </Form.Control>
                    </Form>
                </Panel.Main>
                <Panel.Divider />
                <Panel.Side>
                    <header>
                        <span className='title'>Contenuto</span>
                    </header>
                    {privacyNote.template.locked && <>
                        <Alert>
                            <Icon name='lock' />
                            <span><mark>Informativa di sistema</mark><br/>Le informative di sistema non possono essere modificate</span>
                        </Alert>
                    </>}
                    {!privacyNote.template.locked && <>
                        <Alert>
                            <Icon name='circle-info' />
                            <span>Modifica il contenuto e i quesiti associati.<br/><br/><mark>Nota:</mark> I modelli privi di contenuto o quesiti associati, non potranno essere utilizzati</span>
                        </Alert>
                    </>}
                    <button className='accented-2' onClick={handleTextPatch}>
                        <span>Contenuto e quesiti</span>
                        <Icon name='pen' />
                    </button>
                </Panel.Side>
            </Panel>
        </main>
        <footer>
            <button disabled={privacyNote.template.locked} className='negative-link' onClick={handleDelete}>
                <span>elimina</span>
                <Icon name='trash' />
            </button>
            <button disabled={privacyNote.template.locked} className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} opaque={!privacyNote.id} />
    </>
};

export default Patch;
