const PrivacyNote = {
    id: 0,
    name: '',
    is_active: true,
    //tag: '',
    version: '',
    questions: [],
    template: null
};

export default PrivacyNote;
