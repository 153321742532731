import React, { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import useAuth from '../../hooks/useAuth';

const Logout = () => {
    const api = useApi();
    const auth = useAuth();

    useEffect(() => {
        api.auth.logout().then(res => {
			auth.clear();
		});
    }, []);

    return <>
        <p>disconnessione in corso...</p>
    </>
};

export default Logout;
