import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import MyEditor from './PatchTextEditor';
import Panel from '../../widgets/Panel';
import Prompt from '../../widgets/Prompt';
import Template from '../../models/Template';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const PatchText = ({ id, onChanged, parentDialog }) => {
    const api = useApi();
    const ui = useUI();

    const [template, setTemplate] = useState(Template);
    const [text, setText] = useState('');

    useEffect(() => {
        api.attachments.get(id).then(res => setTemplate(res.data));
    }, []);

    const handleTextChanged = text => {
        setText(text);
    };

    const handleSubmit = e => {
        api.attachments.patchText(id, text).then(res => {
            onChanged();
            
            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Allegato aggiornato'
                    positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    const title = `Modifica contenuto (${template.title})`;

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main style={{ overflowY: 'hidden' }}>
            <Panel>
                <Panel.Main style={{ padding: 0 }}>
                    <MyEditor
                        api={api}
                        onChange={handleTextChanged}
                        template={template} />
                </Panel.Main>
            </Panel>
        </main>
        <footer>
            &nbsp;
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} opaque={!template.id} />
    </>
};

export default PatchText;
