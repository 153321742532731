import React, { cloneElement, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

const UIContext = createContext();

const UIProvider = ({ children }) => {
	const data = useUIProvider();

	return <>
		<UIContext.Provider value={data}>
			{children}
		</UIContext.Provider>
	</>
};

const useUIProvider = () => {
	const location = useLocation();

	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [widgets, setWidgets] = useState([]);
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	useEffect(() => {
		const resize = e => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		};

		window.addEventListener('resize', resize, true);

		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	useEffect(() => {
		//setBreadcrumbs([]);
		setWidgets([]);
	}, [location]);

	useEffect(() => {
		// console.log('ui.widgets', widgets);
	}, [widgets]);

	const dismiss = hash => {
		setWidgets(prevState => {
			return prevState.filter(widget => {
				return widget.props.hash !== hash;
			});
		});
	};

	const show = element => {
		const hash = [...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

		setWidgets(prevState => {
            return [...prevState, {
                element: element,
                props: {
                    hash
                }
            }];
        });

		return hash;
	};

	return {
		breadcrumbs,
		widgets,
		windowSize,
		dismiss,
		setBreadcrumbs,
		show
	};
};

const useUI = () => {
	return useContext(UIContext);
};

const Breadcrumbs = () => {
	const ui = useUI();

	return <>
		<nav className='breadcrumbs'>
			{ui.breadcrumbs.map((crumb, index) =>
				crumb.path
					? <NavLink key={index} to={crumb.path}>{crumb.label}</NavLink>
					: <span key={index}>{crumb.label}</span>
			)}
		</nav>
	</>
};

const Modals = () => {
	const ui = useUI();

	return ui.widgets.map((widget, index) => {
		return cloneElement(widget.element, { key: index, ...widget.props });
	});
};

export default useUI;
export { Breadcrumbs, Modals, UIProvider };
