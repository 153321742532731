const envelopes = client => {
    return {
        prepare: type => client.get(`/api/envelopes/prepare/${type}`),
        overview: hash => client.get(`/api/envelopes/${hash}/overview`),
        post: data => client.post('/api/envelopes', data),
        sign: (hash, id) => client.post(`/api/envelopes/${hash}/sign/${id}`)
    };
};

export default envelopes;
