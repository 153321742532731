import React from 'react';
import { Outlet } from 'react-router';
import { Modals } from '../hooks/useUI';

import './scss/Widget.scss';

const Layout = () => {
	return <>
		<div data-layout='widget'>
			<main>
				<Outlet />
			</main>
		</div>
		<Modals />
	</>
};

export default Layout;
