import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import Loading from '../../widgets/Loading';
import DocumentCover from './DocumentCover';
import { Modals } from '../../hooks/useUI';
import useApi from '../../hooks/useApi';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './scss/Overview.scss';

const Overview = () => {
    const api = useApi();
    const { hash } = useParams();

    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (hash.length !== 25) {
            alert('Formato hash non valido');
            return;
        }

        api.envelopes.overview(hash).then(res => {
            setDocuments(prevState => {
                let documents = res.data.documents;

                documents.map(document => {
                    document.content = `data:application/pdf;base64, ${document.content}`;
                    return document;
                });

                return [...documents];
            });
        });
    }, [hash]);

    return <>
        <div data-layout='sign'>
            <Helmet>
                <title>Documenti</title>
            </Helmet>
            {/* <header>
                <img src='/assets/logo.svg' alt='Lisalex'></img>
            </header> */}
            <main>
                <div className='documents'>
                    {documents.map((document, index) => <DocumentCover key={index} document={document} hash={hash} />)}
                </div>
                <Loading show={api.loading} opaque={true} />
            </main>
            <footer>
                <p className='copy'>&copy; {new Date().getFullYear()} Wichee - Tutti i diritti riservati</p>
			</footer>
        </div>
        <Modals />
    </>
};

export default Overview;
