import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Alert from '../../../widgets/Alert';
import Dialog from '../../../widgets/Dialog';
import Error from '../../../widgets/Error';
import Form from '../../../widgets/Form';
import Icon from '../../../widgets/Icon';
import Loading from '../../../widgets/Loading';
import Prompt from '../../../widgets/Prompt';
import useApi from '../../../hooks/useApi';
import useUI from '../../../hooks/useUI';

const Post = ({ customer, onChanged, parentDialog }) => {
    const title = 'Invia allegati IVASS';

    const api = useApi();
    const postApi = useApi();
    const ui = useUI();

    const [data, setData] = useState({
        'brokers': [],
        'companies': [],
        'consultingServicesNote': null,
        'consultingServices': [],
        'fees': []
    });

    const [broker, setBroker] = useState();
    const [company, setCompany] = useState();
    const [consultingServices, setConsultingServices] = useState([]);
    const [fees, setFees] = useState([]);
    const [hasConsultingServices, setHasConsultingServices] = useState();
    const [internalRef, setInternalRef] = useState('');
    const [productRef, setProductRef] = useState('');
    const [templates, setTemplates] = useState([]);

    const [canSubmit, setCanSubmit] = useState(false);
    const [sendingChannel, setSendingChannel] = useState();

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        /* setCompany();
        setConsultingServices([]);
        setHasConsultingServices();
        setFees([]); */
        setTemplates([]);
    }, [broker]);

    useEffect(() => {
        if (!broker || !templates.length || !sendingChannel) {
            return setCanSubmit(false);
        }

        if (templates.filter(tmpl => [3,4].includes(tmpl.type_id)).length > 0) {
            if (!company || !productRef.trim().length) {
                return setCanSubmit(false);
            }

            if (hasConsultingServices && !consultingServices.length) {
                return setCanSubmit(false);
            }
        }

        if (data.consultingServicesNote?.value == null) {
            return setCanSubmit(false);
        }

        const wrongFees = fees.filter(fee => {
            if (!fee.has_amount) {
                return false;
            }

            if (!fee?.amount) {
                return true;
            }

            if (!fee.amount.trim().length) {
                return true;
            }

            return false;
        });

        if (wrongFees.length > 0) {
            return setCanSubmit(false);
        }

        setCanSubmit(true);
    }, [broker, company, consultingServices, fees, hasConsultingServices, productRef, templates, sendingChannel]);

    const fetch = () => {
        api.envelopes.prepare(2).then(res => {
            if (res.data.consultingServicesNote && res.data.consultingServicesNote.value !== null) {
                setHasConsultingServices(false);
            }

            setData(res.data);
        });
    };

    const handleConsultingServiceSelected = (e, consultingService) => {
        setConsultingServices(prevState => {
            let consultingServices = prevState;
            
            if (!consultingServices.find(cs => cs.id === consultingService.id)) {
                consultingServices.push(consultingService);
            }
            else {
                consultingServices = consultingServices.filter(cs => cs.id !== consultingService.id);
            }

            return [...consultingServices];
        });
    };

    const handleFeeAmountChanged = (e, fee) => {
        setFees(prevState => {
            const fees = prevState;
            
            fees.forEach(_fee => _fee.id === fee.id && (_fee['amount'] = e.target.value));

            return [...fees];
        });
    };

    const handleFeeSelected = (e, fee) => {
        setFees(prevState => {
            let fees = prevState;
            
            if (!fees.find(_fee => _fee.id === fee.id)) {
                fees.push(fee);
            }
            else {
                fees = fees.filter(_fee => _fee.id !== fee.id);
            }

            return [...fees];
        });
    };

    const handleSend = e => {
        const envelope = {
            customer_id: customer.id,
            type: 2,
            sending_channel: sendingChannel,
            data: {
                broker_id: broker.id,
                company_id: company?.id,
                has_consulting_services: hasConsultingServices,
                ...(hasConsultingServices && { 
                    consulting_services: consultingServices.map(cs => {
                        return {
                            id: cs.id
                        }
                    })
                }),
                fees: fees.map(fee => {
                    return {
                        id: fee.id,
                        amount: fee.has_amount ? fee.amount : ''
                    };
                }),
                internal_ref: internalRef,
                product_ref: productRef,
            },
            templates: templates.map(template => {
                return {
                    id: template.id,
                }
            })
        };

        console.log('envelope', envelope);

        postApi.envelopes.post(envelope).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Allegati inviati con successo'
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    const handleTemplateSelected = (e, template) => {
        setTemplates(prevState => {
            let templates = prevState;
            
            if (!templates.find(tmpl => tmpl.id === template.id)) {
                if ([3,4].includes(template.type_id)) {
                    templates = templates.filter(tmpl => ![3,4].includes(tmpl.type_id));
                }

                templates.push(template);
            }
            else {
                templates = templates.filter(tmpl => tmpl.id !== template.id);
            }

            return [...templates];
        });
    };

    const addressbook = customer?.address_books.find(addressbook => addressbook.status_addressbook.value == 0);

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            {!api.error && <>
                <div style={{ padding: '.2rem 2rem', borderBottom: '.1rem solid var(--colorBorder)' }}>
                    <Alert mode='accented'>
                        {customer.status_registry.value == 0 && <Icon name='user' />}
                        {customer.status_registry.value == 1 && <Icon name='briefcase' />}
                        <span><mark>{customer.title}</mark><br/>{customer.correspondences.length && 
                            <div className='location'>
                                <Icon name='location-dot'/>&nbsp;&nbsp;{customer.correspondences[0].geo_location.COUNCIL}, {customer.correspondences[0].geo_location.PRVCODE}
                            </div>
                        }</span>
                    </Alert>
                </div>
                {!addressbook?.attributables?.MAIL && (data.config.use_sms && !addressbook?.attributables?.CELL)
                    ? <>
                        <div style={{ padding: '.2rem 2rem', borderBottom: '.1rem solid var(--colorBorder)' }}>
                            <Alert mode='error'>
                                <Icon name='triangle-exclamation' />
                                <span><mark>Contatti mancanti</mark><br/>Questo cliente non dispone dei contatti necessari per l'invio</span>
                            </Alert>
                        </div>
                    </>
                    : <>
                        <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 2rem 8rem 2rem' }}>
                            <Form.Control label='Produttore' errors={api.errors?.broker_id}>
                                {data.brokers.filter(broker => broker.attachments.length > 0).length > 0 
                                    ? <>
                                        <Form.InputLabel>
                                            <Icon name='book-user' />
                                            <select
                                                autoFocus
                                                onChange={e => setBroker(data.brokers.find(broker => broker.id == e.target.value))}
                                                value={broker?.id || 0}>
                                                <option disabled value={0}>Seleziona produttore</option>
                                                {data.brokers.map((broker, index) => {
                                                    return <option disabled={!broker.attachments.length} key={index} value={broker.id}>{broker.code} - {broker.title}</option>
                                                })}
                                            </select>
                                        </Form.InputLabel>
                                        <Form.Tip text='Seleziona tra i produttori disponibili' />
                                    </>
                                    : <>
                                        <Alert mode='error'>
                                            <Icon name='triangle-exclamation'/>
                                            <span><mark>Produttori non disponibili</mark><br/>Devi configurare almeno un produttore prima di continuare.</span>
                                        </Alert>
                                    </>
                                }
                            </Form.Control>
                            <hr/>
                            <Form.Control label='Riferimento interno (facoltativo)' errors={api.errors?.internal_ref}>
                                <Form.InputLabel>
                                    <Icon name='pen' />
                                    <input
                                        maxLength={120}
                                        onChange={e => setInternalRef(e.target.value)}
                                        placeholder='Esempio: G100020002'
                                        value={internalRef} />
                                </Form.InputLabel>
                            </Form.Control>
                            {broker && <>
                                <hr/>
                                <Alert>
                                    {/* <Icon name='paperclip'/> */}
                                    <span><mark>Allegati</mark><br/>Seleziona uno o più allegati associati al produttore selezionato.</span>
                                </Alert>
                                <Form.Control label='Allegati produttore'>
                                    {broker.attachments.map((tmpl, index) => {
                                        if ([2,5].includes(tmpl.type_id)) {
                                            return (
                                                <label key={`brokerAttachment-${tmpl.id}`} className='check'>
                                                    <input
                                                        onChange={e => handleTemplateSelected(e, tmpl)}
                                                        checked={templates.filter(_tmpl => _tmpl.id === tmpl.id).length}
                                                        type='checkbox' />
                                                    <span className='mark' />
                                                    <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                                        <Icon name='paperclip' style={{ color: 'var(--colorText2)' }} />
                                                        <span style={{ color: 'var(--colorText)', flex: 1 }}>{tmpl.title}</span>
                                                    </span>
                                                </label>
                                            );
                                        }
                                    })}
                                    <Alert mode='error' show={!api.loading && !broker.attachments.filter(tmpl => [2,5].includes(tmpl.type_id)).length}>
                                        <Icon name='triangle-exclamation' />
                                        <span><mark>Allegati non disponibili</mark><br/>Questo produttore non presenta allegati 3 o 4-TER</span>
                                    </Alert>
                                </Form.Control>
                                <hr/>
                                <Form.Control label='Allegati prodotto'>
                                    {broker.attachments.map((tmpl, index) => {
                                        if ([3,4].includes(tmpl.type_id)) {
                                            return (
                                                <label key={`productAttachment-${tmpl.id}`} className='check'>
                                                    <input
                                                        onChange={e => handleTemplateSelected(e, tmpl)}
                                                        checked={templates.filter(_tmpl => _tmpl.id === tmpl.id).length}
                                                        name='product-attachments'
                                                        type='radio' />
                                                    <span className='mark' />
                                                    <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                                        <Icon name='paperclip' style={{ color: 'var(--colorText2)' }} />
                                                        <span style={{ color: 'var(--colorText)', flex: 1 }}>{tmpl.title}</span>
                                                    </span>
                                                </label>
                                            );
                                        }
                                    })}
                                    <Alert mode='error' show={!api.loading && !broker.attachments.filter(tmpl => [3,4].includes(tmpl.type_id)).length}>
                                        <Icon name='triangle-exclamation' />
                                        <span><mark>Allegati non disponibili</mark><br/>Questo produttore non presenta allegati 4 o 4-BIS</span>
                                    </Alert>
                                </Form.Control>
                                {templates.filter(tmpl => [3,4].includes(tmpl.type_id)).length > 0 && <>
                                    <hr/>
                                    <Form.Control label='Riferimento prodotto' errors={api.errors?.product_ref}>
                                        <Form.InputLabel>
                                            <Icon name='pen' />
                                            <input
                                                maxLength={120}
                                                onChange={e => setProductRef(e.target.value)}
                                                placeholder='Esempio: G100023456'
                                                value={productRef} />
                                        </Form.InputLabel>
                                        <Form.Tip text='Inserire un riferimento al contratto che si sta intermediando (es. rif. Prodotto / num. Proposta / num. preventivo / etc... )' />
                                    </Form.Control>
                                    <hr/>
                                    <Form.Control label='Compagnia' errors={api.errors?.company_id}>
                                        {data.companies.filter(company => company.brokerage_relationship !== null).length > 0
                                            ? <>
                                                <Form.InputLabel>
                                                    <Icon name='briefcase' />
                                                    <select
                                                        onChange={e => setCompany(data.companies.find(company => company.id == e.target.value))}
                                                        value={company?.id || 0}>
                                                        <option disabled value={0}>Seleziona compagnia</option>
                                                        {data.companies.map((company, index) => {
                                                            return <option disabled={!company.brokerage_relationship} key={index} value={company.id}>{company.code} - {company.title}</option>
                                                        })}
                                                    </select>
                                                </Form.InputLabel>
                                                <Form.Tip text='Seleziona tra le compagnie disponibili' />
                                            </>
                                            : <>
                                                <Alert mode='error'>
                                                    <Icon name='triangle-exclamation'/>
                                                    <span><mark>Compagnie non disponibili</mark><br/>Devi configurare almeno una compagnia prima di continuare.</span>
                                                </Alert>
                                            </>
                                        }
                                    </Form.Control>
                                    <hr/>
                                    <Form.Control label='È stata erogata consulenza?' style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <label disabled={!data.consultingServicesNote || data.consultingServicesNote?.value === null} className='check' style={{ width: 'auto' }}>
                                            <input
                                                onChange={() => setHasConsultingServices(true)}
                                                checked={hasConsultingServices == true}
                                                name='has-consulting-services'
                                                type='radio' />
                                            <span className='mark' />
                                            <span className='label'>Si</span>
                                        </label>
                                        <label disabled={!data.consultingServicesNote || data.consultingServicesNote?.value === null} className='check' style={{ width: 'auto' }}>
                                            <input
                                                onChange={() => setHasConsultingServices(false)}
                                                checked={hasConsultingServices == false}
                                                name='has-consulting-services'
                                                type='radio' />
                                            <span className='mark' />
                                            <span className='label'>No</span>
                                        </label>
                                    </Form.Control>
                                    <Alert show={data.consultingServicesNote?.value == null} mode='error'>
                                        <Icon name='triangle-exclamation'/>
                                        <span><mark>Consulenze non disponibili</mark><br/>Devi configurare la nota "Nessuna consulenza" prima di continuare.</span>
                                    </Alert>
                                    {hasConsultingServices && <>
                                        <Form.Control label='Consulenze'>
                                            {data.consultingServices.map((consultingService, index) =>
                                                <label key={index} className='check'>
                                                    <input
                                                        onChange={e => handleConsultingServiceSelected(e, consultingService)}
                                                        checked={consultingServices.filter(cs => cs.id === consultingService.id).length}
                                                        type='checkbox' />
                                                    <span className='mark' />
                                                    <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                                        <Icon name='file' style={{ color: 'var(--colorText2)' }} />
                                                        <span style={{ color: 'var(--colorText)', flex: 1 }}>{consultingService.title}</span>
                                                    </span>
                                                </label>
                                            )}
                                            <Alert mode='error' show={!api.loading && !data.consultingServices.length}>
                                                <Icon name='triangle-exclamation' />
                                                <span><mark>Consulenze non disponibili</mark><br/>Devi aggiungere almeno una consulenza prima di continuare.</span>
                                            </Alert>
                                        </Form.Control>
                                    </>}
                                    <hr/>
                                    <Form.Control label='Remunerazioni'>
                                        {data.fees.map((fee, index) => {
                                            return <>
                                                <label key={index} className='check' style={{ flexWrap: 'wrap' }}>
                                                    <input
                                                        onChange={e => handleFeeSelected(e, fee)}
                                                        checked={fees.filter(_fee => _fee.id === fee.id).length}
                                                        type='checkbox' />
                                                    <span className='mark' />
                                                    <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                                        <Icon name='sack-dollar' style={{ color: 'var(--colorText2)' }} />
                                                        <span style={{ color: 'var(--colorText)', flex: 1 }}>{fee.title}</span>
                                                    </span>
                                                </label>
                                                {fees.filter(_fee => _fee.id === fee.id).length > 0 && fee.has_amount == true && <>
                                                    <Form.InputLabel>
                                                        <Icon name='pen' />
                                                        <input
                                                            maxLength={120}
                                                            onChange={e => handleFeeAmountChanged(e, fee)}
                                                            placeholder='Somma o formula'
                                                            value={fee.amount} />
                                                    </Form.InputLabel>
                                                    <Form.Tip text="Indicare la somma percepita oppure la formula utilizzata per calcolarla" />
                                                </>}
                                            </>
                                        })}
                                        <Alert mode='error' show={!api.loading && !data.fees.length}>
                                            <Icon name='triangle-exclamation' />
                                            <span>Non sono presenti remunerazioni per la tua agenzia</span>
                                        </Alert>
                                    </Form.Control>
                                </>}
                                <hr/>
                                <div>
                                    <Alert>
                                        <span><mark>Invio</mark><br/>Seleziona la modalità di invio degli allegati.</span>
                                    </Alert>
                                    <div className='sendingChannels'>
                                        <div aria-disabled={!addressbook?.attributables?.MAIL} role='button' data-active={sendingChannel === 1} onClick={e => setSendingChannel(1)}>
                                            <Icon name='at' />
                                            <span>E-mail</span>
                                        </div>
                                        <div aria-disabled={!data.config.use_sms || !addressbook?.attributables?.CELL} role='button' data-active={sendingChannel === 2} onClick={e => setSendingChannel(2)}>
                                            <Icon name='envelope' />
                                            <span>SMS</span>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </Form>
                    </>
                }
            </>}
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </main>
        <footer style={{ background: 'transparent' }}>
            <button style={{ width: '100%' }} disabled={!canSubmit} className='accented' onClick={e => handleSend(e)}>
                <span>Invia allegati IVASS</span>
                <Icon name='paper-plane' />
            </button>
        </footer>
        <Loading show={api.loading || postApi.loading} opaque={!data.brokers.length} />
    </>
};

export default Post;
