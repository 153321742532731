import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import MyEditor from './PatchTextEditor';
import Panel from '../../widgets/Panel';
import PrivacyNote from '../../models/PrivacyNote';
import Prompt from '../../widgets/Prompt';
import Questions from './Questions';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const PatchText = ({ id, onChanged, parentDialog }) => {
    const api = useApi();
    const ui = useUI();

    const [privacyNote, setPrivacyNote] = useState(PrivacyNote);
    const [text, setText] = useState('');

    useEffect(() => {
        fetch();
    }, [id]);

    const fetch = () => {
        api.privacyNotes.get(id).then(res => {
            setPrivacyNote(res.data);
        })
    };

    const handleChanged = () => {
        fetch();
    };

    const handleTextChanged = text => {
        setText(text);
    };

    const handleSubmit = e => {
        api.privacyNotes.patchText(privacyNote.id, text).then(res => {
            handleChanged();

            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Informativa aggiornata'
                    positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    const title = `Modifica contenuto (${privacyNote.name})`;

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        {privacyNote?.id && <>
            <main style={{ overflowY: 'hidden' }}>
                <Panel>
                    <Panel.Side>
                        <Questions privacyNote={privacyNote} onChanged={handleChanged} />
                    </Panel.Side>
                    <Panel.Divider />
                    <Panel.Main style={{ padding: 0 }}>
                        <MyEditor
                            api={api}
                            onChange={handleTextChanged}
                            privacyNote={privacyNote} />
                    </Panel.Main>
                </Panel>
            </main>
            <footer>
                &nbsp;
                <button disabled={privacyNote.template.locked} className='accented' onClick={handleSubmit}>
                    <span>Conferma modifiche</span>
                </button>
            </footer>
        </>}
        <Loading show={api.loading} opaque={!privacyNote?.id} />
    </>
};

export default PatchText;
