import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../../widgets/Dialog';
import Icon from '../../../widgets/Icon';
import Loading from '../../../widgets/Loading';
import Prompt from '../../../widgets/Prompt';
import useApi from '../../../hooks/useApi';
import useUI from '../../../hooks/useUI';
import Alert from '../../../widgets/Alert';
import Form from '../../../widgets/Form';

import './Post.scss';

const Post = ({ defaultNoteId, customer, onChanged, parentDialog }) => {
    const title = 'Gestisci consensi';

    const api = useApi();
    const ui = useUI();

    const [config, setConfig] = useState();
    const [privacyNote, setPrivacyNote] = useState();
    const [privacyNotes, setPrivacyNotes] = useState([]);

    const [canSubmit, setCanSubmit] = useState(false);
    const [sendingChannel, setSendingChannel] = useState();

    useEffect(() => {
        api.envelopes.prepare(1).then(res => {
            setConfig(res.data.config);
            setPrivacyNotes(res.data.notes);

            if (res.data.length == 1) {
                setPrivacyNote(res.data.notes[0]);
            }

            if (defaultNoteId) {
                setPrivacyNote(res.data.notes.find(note => note.id === defaultNoteId));
            }
        });
    }, []);

    useEffect(() => {
        if (!privacyNote || !privacyNote.questions.length || !sendingChannel) {
            return setCanSubmit(false);
        }

        const mandatories = privacyNote.questions.filter(question => question.is_mandatory == true);
        const mandatoriesCheck = mandatories.filter(question => question.value === true);

        setCanSubmit(mandatories.length === mandatoriesCheck.length);
    }, [privacyNote, sendingChannel]);

    const handleConsentSelected = (e, letter) => {
        setPrivacyNote(prevState => {
            return {
                ...prevState,
                questions: prevState.questions.map(question => {
                    if (question.letter === letter) {
                        question.value = e.target.checked;
                    }

                    return question;
                })
            }
        });
    };

    const handleSend = e => {
        const envelope = {
            customer_id: customer.id,
            type: 1,
            sending_channel: sendingChannel,
            templates: [],
            data: {
                questions: privacyNote.questions.map(question => { 
                    return {
                        id: question.id,
                        letter: question.letter,
                        name: question.name,
                        value: question?.value || false
                    }
                })
            }
        };

        const tmpl = {
            id: privacyNote.template.id,
            type_id: 1,
        };

        envelope.templates.push(tmpl);

        api.envelopes.post(envelope).then(res => {
            onChanged();
            
            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Informativa inviata con successo'
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    const handlePrivacyNoteSelected = note => {
        setPrivacyNote(note);
    };

    const addressbook = customer?.address_books.find(addressbook => addressbook.status_addressbook.value == 0);

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <div style={{ padding: '.2rem 2rem', borderBottom: '.1rem solid var(--colorBorder)' }}>
                <Alert mode='accented'>
                    {customer.status_registry.value == 0 && <Icon name='user' />}
                    {customer.status_registry.value == 1 && <Icon name='briefcase' />}
                    <span><mark>{customer.title}</mark><br/>{customer.correspondences.length && 
                        <div className='location'>
                            <Icon name='location-dot'/>&nbsp;&nbsp;{customer.correspondences[0].geo_location.COUNCIL}, {customer.correspondences[0].geo_location.PRVCODE}
                        </div>
                    }</span>
                </Alert>
            </div>
            {!addressbook?.attributables?.MAIL && (config?.use_sms && !addressbook?.attributables?.CELL)
                ? <>
                    <div style={{ padding: '.2rem 2rem', borderBottom: '.1rem solid var(--colorBorder)' }}>
                        <Alert mode='error'>
                            <Icon name='triangle-exclamation' />
                            <span><mark>Contatti mancanti</mark><br/>Questo cliente non dispone dei contatti necessari per l'invio</span>
                        </Alert>
                    </div>
                </>
                : <>
                <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 2rem 8rem 2rem' }}>
                    <Form.Control label='Seleziona informativa' style={{ paddingBottom: '1rem' }}>
                        {privacyNotes.map((note, index) =>
                            <label key={index} className='check'>
                                <input
                                    onChange={() => handlePrivacyNoteSelected(note)}
                                    checked={privacyNote?.id === note.id}
                                    name='templates'
                                    type='radio' />
                                <span className='mark' />
                                <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem', justifyContent: 'space-between' }}>
                                    <Icon name='file' style={{ color: 'var(--colorText2)' }} />
                                    <span style={{ color: 'var(--colorText)', flex: 1 }}>{note.name}</span>
                                    <span style={{ color: 'var(--accent)', fontWeight: 'var(--fontMedium)' }}>{note.questions.length} quesit{note.questions.length != 1 ? 'i' : 'o'}</span>
                                </span>
                            </label>
                        )}
                        <Alert mode='error' show={!api.loading && !privacyNotes.length}>
                            <Icon name='triangle-exclamation'/>
                            <span>Non ci sono informative da mostrare</span>
                        </Alert>
                    </Form.Control>
                    {privacyNote && <>
                        <Form.Control label='Consensi'>
                            {!privacyNote.questions.length && <>
                                <Alert mode='error'>
                                    <Icon name='triangle-exclamation negative' />
                                    <span><mark>Quesiti mancanti</mark><br/>Non puoi utilizzare questa informativa perché è priva di quesiti associati.{/* <br/><br/>Informativa: <mark>{template.title}</mark> */}</span>
                                </Alert>
                            </>}
                            {privacyNote.questions.map((question, index) =>
                                <label key={index} className='check'>
                                    <input
                                        onChange={e => handleConsentSelected(e, question.letter)}
                                        checked={question?.value === true}
                                        type='checkbox' />
                                    <span className='mark' />
                                    <span className='label' style={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
                                        <span style={{ color: 'var(--accent)', fontWeight: 'var(--fontBold)' }}>{question.letter}</span>
                                        <span style={{ color: 'var(--colorText)', flex: 1 }}>{question.name}</span>
                                        {question.is_mandatory == true && <span style={{ color: 'var(--accent)', fontWeight: 'var(--fontMedium)' }}>Obbligatorio</span>}
                                    </span>
                                </label>
                            )}
                        </Form.Control>
                    </>}
                    <hr/>
                    <div>
                        <Alert>
                            <span><mark>Invio</mark><br/>Seleziona la modalità di invio dell'informativa.</span>
                        </Alert>
                        <div className='sendingChannels'>
                            <div aria-disabled={!addressbook?.attributables?.MAIL} role='button' data-active={sendingChannel === 1} onClick={e => setSendingChannel(1)}>
                                <Icon name='at' />
                                <span>E-mail</span>
                            </div>
                            <div aria-disabled={!config?.use_sms || !addressbook?.attributables?.CELL} role='button' data-active={sendingChannel === 2} onClick={e => setSendingChannel(2)}>
                                <Icon name='envelope' />
                                <span>SMS</span>
                            </div>
                        </div>
                    </div>
                </Form>
            </>}
        </main>
        <footer style={{ background: 'transparent' }}>
            <button style={{ width: '100%' }} disabled={!canSubmit} className='accented' onClick={e => handleSend(e)}>
                <span>Invia per la firma</span>
                <Icon name='paper-plane' />
            </button>
        </footer>
        <Loading show={api.loading} opaque={!sendingChannel} />
    </>
};

export default Post;
