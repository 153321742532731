import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import useApi from '../../hooks/useApi';
import useAuth from '../../hooks/useAuth';

const Login = ({ parentDialog }) => {
	const title = !parentDialog ? 'Accedi' : 'Accedi per continuare';

	const api = useApi();
	const auth = useAuth();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();
		
		api.auth.login(username, password).then(res => {
			auth.refresh(res);
			parentDialog && parentDialog.dismiss();
		});
	};

	return <>
		<Helmet>
			<title>{title}</title>
		</Helmet>
		<Form onSubmit={handleSubmit} style={{ padding: '2rem' }}>
			<h1 className='title'>{title}</h1>
			<Form.Control disabled={api.loading} label='Username' errors={api.errors?.username}>
				<Form.InputLabel>
					<Icon name='user-circle' />
					<input
						autoFocus
						onChange={e => setUsername(e.target.value)}
						placeholder='Username'
						value={username} />
				</Form.InputLabel>
			</Form.Control>
			<Form.Control disabled={api.loading} label='Password' errors={api.errors?.password}>
				<Form.InputLabel>
					<Icon name='key' />
					<input
						onChange={e => setPassword(e.target.value)}
						placeholder='Password'
						type={showPassword ? 'text' : 'password'}
						value={password} />
					{showPassword
						? <button type='button' onClick={e => setShowPassword(false)} className='action'>Nascondi</button>
						: <button type='button' onClick={e => setShowPassword(true)} className='action'>Mostra</button>
					}
				</Form.InputLabel>
			</Form.Control>
			<button 
				style={{ marginTop: '2rem' }}
				disabled={api.loading}
				data-loading={api.loading}
				type='submit'
				className='submit'>Accedi
			</button>
			<p className='copy'>Le tue informazioni vengono utilizzate per permetterti di accedere in modo sicuro. Teniamo traccia di alcuni dati per ragioni di sicurezza e supporto. <a className='link' href='https://wichee.com' target='_blank'>Scopri come gestiamo i tuoi dati.</a></p>
		</Form>
	</>
};

export default Login;
