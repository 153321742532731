import React, { cloneElement, createRef } from 'react';
import { Transition } from 'react-transition-group';
import useUI from '../hooks/useUI';
import './scss/Dialog.scss';

const Dialog = ({ hash, exiting, direction='top|bottom', width='auto', height='auto', children }) => {
	const ui = useUI();
	const ref = createRef();

	const dismiss = () => {
		ui.dismiss(hash);
	};

	const style = {
		width,
		height
	};

	return <>
		<Transition
			appear={true}
			in={!exiting}
			timeout={{
				appear: 0,
				enter: 250,
				exit: 250
			}}
			unmountOnExit>
			{state => {
				const clazzes = [
					'dialogBackdrop',
					state
				];

				return <>
					<div className={clazzes.join(' ')} data-direction={direction} role='dialog'>
						<div ref={ref} style={style}>
							{React.Children.map(children, child => {
								return cloneElement(child, {
									parentDialog: {
										dismiss,
                                        hash
									}
								})
							})}
						</div>
					</div>
				</>
			}}
		</Transition>
	</>
};

export default Dialog;
