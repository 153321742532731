import React from 'react';
import './scss/Form.scss';

const Control = ({ children, errors, label, style }) => {
    return <>
        <div className='control' style={style}>
            {label && <label className='label'>{label}</label>}
            {children}
            {errors && <span className='error'>{errors.join('<br/')}</span>}
        </div>
    </>
};

class ButtonGroup extends React.Component {
    static Item = ({ name, onChange, label, negative, positive, selected, value }) => {
        const className = [
            negative ? 'negative' : undefined,
            positive ? 'positive' : undefined,
        ];
        
        return <>
            <label role='button'>
                <input
                    checked={selected}
                    name={name}
                    onChange={onChange}
                    type='radio'
                    value={value} />
                <span className={className.join(' ')}>{label}</span>
            </label>
        </>
    };
    
    render() {
        return <>
            <label className='btn-group'>
                {this.props.children}
            </label>
        </>
    }
}

const InputLabel = ({ children, label }) => {
    return <>
        <label className='input'>
            {children}
        </label>
    </>
};

const TextLength = ({ text, max }) => {
    return <span className='text-length'>{(text || '').length}/{max}</span>
};

const Tip = ({ text }) => {
    return <span className='tip'>{text}</span>
};

const FormElement = ({ children, onSubmit, style }) => {
    return <form className='form' onSubmit={onSubmit} style={style}>{children}</form>
};

class Form extends React.Component {
    static Control = Control;
    static ButtonGroup = ButtonGroup;
    static InputLabel = InputLabel;
    static TextLength = TextLength;
    static Tip = Tip;
    
    constructor(props) {
        super(props);
    }

    render() {
        return <FormElement onSubmit={this.props.onSubmit} style={this.props.style}>
            {this.props.children}
        </FormElement>
    }
}

export default Form;
