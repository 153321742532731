import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const defaultConfig = {
    branding: false,
    //plugins: 'pagebreak tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
    //plugins: 'pagebreak anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount mergetags',
    plugins: 'mergetags lists pagebreak table',
    toolbar: 'undo redo | mergetags | blocks fontsize | bold italic underline strikethrough | link image media table | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    placeholder: 'Inserisci il testo della tua informativa',
    tinycomments_mode: 'embedded',
    tinycomments_author: 'Author name',
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: [
        { title: 'Cliente', menu: [
            { value: 'Cliente.RagioneSociale', title: 'Ragione Sociale' },
            { value: 'Cliente.CodFiscale', title: 'Codice Fiscale' },
            { value: 'Cliente.PIVA', title: 'Partita IVA' },
            { value: 'Cliente.Email', title: 'Indirizzo e-mail' },
            { value: 'Cliente.Cellulare', title: 'Cellulare' },
            { value: 'Cliente.Telefono', title: 'Telefono' },
            { value: 'Cliente.Indirizzo', title: 'Indirizzo' },
        ]},
    ],
    language: 'it',
    height: '100%',
    content_style: 'body { font-family: Manrope, sans-serif; font-size:14px }'
    //ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
};

const MyEditor = ({ api, onChange, privacyNote }) => {
    const editorRef = useRef(null);
    const textRef = useRef('');
    const previewTextRef = useRef('');
    
    const [text, setText] = useState('');
    
    const getConfig = () => {
        const config = JSON.parse(JSON.stringify(defaultConfig));

        // TinyMCE doesn't allow empty array of tags
        if (privacyNote.questions.length > 0) {
            config.mergetags_list.push({
                title: 'Quesiti',
                menu: privacyNote.questions.map((question, index) => {
                    return { title: question.letter, menu: [
                        { value: `Quesito${question.letter}.Si`, title: 'Box Si' },
                        { value: `Quesito${question.letter}.No`, title: 'Box No' }
                    ]};
                })
            });
        }

        config.mergetags_list.push({ value: 'Documento.Firma', title: 'Firma' });
        
        return config;
    };

    useEffect(() => {
        setText(privacyNote.template.text || '');
    }, [privacyNote]);

    useEffect(() => {
        onChange(text);
        
        textRef.current = text;
        previewTextRef.current = getPreviewContent(text);
    }, [text]);
    
    const getPreviewContent = content => {
        let out = content;

        out = out.replaceAll('{{Cliente.RagioneSociale}}', 'Rossi Mario');
        out = out.replaceAll('{{Cliente.CodFiscale}}', 'RSSMRA80L05F593A');
        out = out.replaceAll('{{Cliente.PIVA}}', '02698850985');
        out = out.replaceAll('{{Cliente.Email}}', 'mario.rossi@email.com');
        out = out.replaceAll('{{Cliente.Cellulare}}', '333 33 33 333');
        out = out.replaceAll('{{Cliente.Telefono}}', '+39 02 00001234');
        out = out.replaceAll('{{Cliente.Indirizzo}}', 'Via Vittor Pisani, 13 - 20124 Milano MI');

        privacyNote.questions.map((question, index) => {
            out = out.replaceAll(`{{Quesito${question.letter}.Si}}`, '<span data-answer="true" style="display: inline-block; width: 16px; height: 16px; border: 1px solid #000">&nbsp;</span>');
            out = out.replaceAll(`{{Quesito${question.letter}.No}}`, '<span data-answer="false" style="display: inline-block; width: 16px; height: 16px; border: 1px solid #000">&nbsp;</span>');
        });

        const signature = `<p style="line-height: 14px; width: 280px; height: 40px; border: 1px solid #000; padding: 2px;">Rossi Mario<br/>01/01/2024 00:00:00<br/>127.0.0.1</p>`;

        out = out.replaceAll('{{Documento.Firma}}', signature);
        
        return out;
    };
    
    const handleChange = (content, editor) => {
        setText(content);
    };

    const handleInit = (evt, editor) => {
        editorRef.current = editor;

        editor.on('BeforeExecCommand', evt => {
            if (evt.command === 'mcePrint') {
                editorRef.current.setContent(previewTextRef.current);
            }
        });
        
        editor.on('ExecCommand', evt => {
            if (evt.command === 'mcePrint') {
                editorRef.current.setContent(textRef.current);
            }
        });
    };
    
    if (api.loading) {
        return null;
    }

    return <>
        <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            disabled={privacyNote.template.locked}
            init={getConfig()}
            defaultValue={privacyNote.template?.text || ''}
            value={text}
            onEditorChange={handleChange}
            onInit={handleInit}
        />
    </>
};

export default MyEditor;
