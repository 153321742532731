import React, { useEffect, useState } from 'react';

import Alert from '../../../widgets/Alert';
import ConsentSendingDetail from './ConsentSendingDetail';
import ConsentSignDetail from './ConsentSignDetail';
import Dialog from '../../../widgets/Dialog';
import Icon from '../../../widgets/Icon';
import Loading from '../../../widgets/Loading';
import Post from '../../Envelopes/PrivacyNotes/Post';
import useApi from '../../../hooks/useApi';
import useDate from '../../../hooks/useDate';
import useUI from '../../../hooks/useUI';

const Envelopes = ({ customer, parentDialog }) => {
    const api = useApi();
    const date = useDate();
    const ui = useUI();

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        api.customers._logs.privacyNotes(customer.id).then(res => {
            setLogs(res.data);
        })
    }, []);

    const handleDetail = log => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <ConsentSendingDetail log={log} />
        </Dialog>);
    };

    return <>
        <header>
            <span>Informative inviate</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <div data-component='customerEnvelopes'>
                {logs.length > 0 && <>
                    <div className='envelope'>
                        <span className='date'>Data e ora</span>
                        <span className='title'>Tipo</span>
                        <span className='channel'>Canale di invio</span>
                    </div>
                    {logs.map((log, index) =>
                        <div key={index} role='button' className='envelope' onClick={() => handleDetail(log)}>
                            <span className='date'>{date.locale(log.created_at)}</span>
                            <span className='title'>{log.document.data?.template?.title || 'Modello sconosciuto'}</span>
                            <span className='channel'>{log.data.channel}</span>
                        </div>
                    )}
                </>}
                <Alert mode='info' show={!api.loading && !logs.length}>
                    <Icon name='circle-info'/>
                    <span>Non ci sono dati da mostrare</span>
                </Alert>
            </div>
        </main>
        <Loading show={api.loading && !api.error} opaque={!logs.length} />
    </>
};

const Consent = ({ customer, onChanged, widget = false }) => {
    const ui = useUI();

    //console.log('consent', customer);
    
    const handleDetail = log => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <ConsentSendingDetail log={log} />
        </Dialog>);
    };

    const handleEnvelopePost = id => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <Post defaultNoteId={id} customer={customer} onChanged={onChanged} />
        </Dialog>);
    };

    const handleEnvelopes = e => {
        ui.show(<Dialog width='48rem'>
            <Envelopes customer={customer} />
        </Dialog>);
    };

    const handleSignatureDetail = note => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <ConsentSignDetail note={note} />
        </Dialog>);
    };

    return <>
        <header>
            <span className='title'>Consensi privacy</span>
            <span role='button' className='count' onClick={handleEnvelopes}>
                <Icon name='paper-plane'/> Log invio
            </span>
        </header>
        <div className='privacyNotes'>
            {customer.privacyNotes.map((note, index) => {
                if (note.has_consent === true) {
                    return (
                        <div key={index} role='button' className='privacyNote' onClick={() => handleSignatureDetail(note)}>
                            <div className='title'>{note.name}</div>
                            <div className='consentList'>
                                {note.questions.map((question, index) =>
                                    <div key={index} className={'consent ' + (question?.answer?.value ? 'positive' : 'negative')}>
                                        <Icon name={question?.answer?.value ? 'check-circle' : 'times-circle'} />
                                        <span>{question.name}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }

                if (note.sendingLog) {
                    return (
                        <div key={index} role='button' className='privacyNote' onClick={() => handleDetail(note.sendingLog)}>
                            <div className='title'>{note.name}</div>
                            <div className='consentList'>
                                {note.questions.map((question, index) =>
                                    <div key={index} className='consent negative'>
                                        <Icon name='times-circle' />
                                        <span>{question.name}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }
                
                return (
                    <div key={index} role='button' className='privacyNote' onClick={() => handleEnvelopePost(note.id)}>
                        <div className='title'>{note.name}</div>
                        <div className='consentList'>
                            {note.questions.map((question, index) =>
                                <div key={index} className='consent neutral'>
                                    <Icon name='times-circle'/>
                                    <span>{question.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
        <button className='accented' onClick={e => handleEnvelopePost(e)}>
            <span>Gestisci consensi</span>
            <Icon name='badge-check' />
        </button>
        {/* {widget && <footer className='lisalexPowered'>
            <span>Powered by</span><img src='/assets/logo.svg' alt='Lisalex' />
        </footer>} */}
    </>
}

export default Consent;
