import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const BrowseContext = createContext();

const BrowseProvider = ({ children }) => {
	const data = useBrowseProvider();

	return (
		<BrowseContext.Provider value={data}>
			{children}
		</BrowseContext.Provider>
	)
}

const useBrowseProvider = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const getParams = () => {
		const out = [];

		searchParams.forEach((value, key) => {
			out.push({ key, value })
		});

		return out;
	}

	const [booted, setBooted] = useState(false);
	const [params, setParams] = useState(getParams());
	const [submitted, setSubmitted] = useState(0);

	useEffect(() => {
		setBooted(true);
	}, []);

	useEffect(() => {
		if (submitted) {
			return setSubmitted(0);
		}
		
		if (booted) {	
			console.warn('searchParams', submitted, location);
			setParams(getParams());
		}
	}, [searchParams]);

	useEffect(() => {
		if (submitted) {
			setSearchParams(queryString());
		}
	}, [submitted]);

	const get = (key, _default) => {
		const param = params.find(param => param.key == key);

		//console.log('get', param);

		if (param) {
			return param.value;
		}

		return _default;
	};

	const put = (key, value, _submit = false) => {
		setParams(params => {
			const index = params.findIndex(param => param.key == key);

			if (index === -1) {
				params.push({ key, value });
			}
			else {
				params.forEach(param => {
					if (param.key == key) {
						param.value = value;
					}

					return param;
				});
			}

			_submit && submit();
			
			return [...params];
        });
	};

	const queryString = () => {
		let out = [];

		params.map(param => {
			if (param.value instanceof Array) {
				param.value.map(value => {
					out.push(`${param.key}[]=${value}`);
				});
			}
			else {
				out.push(`${param.key}=${param.value}`);
			}
		});

		return (out.length ? '?' : '') + out.join('&');
	};

	const remove = (key, _submit = false) => {
		setParams(prevState => {
			_submit && submit();

			return prevState.filter(param => {
				return param.key !== key;
			});
		});
	};

	const submit = () => {
		setSubmitted(s => s +1);
	};

	return {
		params,
		submitted,
		get,
		put,
		queryString,
		remove,
		submit
	}
};

const useBrowse = () => {
	return useContext(BrowseContext);
};

export default useBrowse
export { BrowseProvider };
