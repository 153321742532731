const attachments = client => {
    return {
        index: () => client.get('/api/attachments'),
        get: id => client.get(`/api/attachments/${id}`),
        post: data => client.post('/api/attachments', data),
        patch: (id, data) => client.post(`/api/attachments/${id}/patch`, data),
        patchText: (id, text) => client.post(`/api/attachments/${id}/patch/text`, { text }),
        delete: id => client.post(`/api/attachments/${id}/delete`),
        prepareImport: () => client.get('/api/attachments/prepare-import'),
        import: data => client.post('/api/attachments/import', data)
    };
};

export default attachments;
