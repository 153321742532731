import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Prompt from '../../widgets/Prompt';
import useUI from '../../hooks/useUI';
import useApi from '../../hooks/useApi';

const Patch = ({ _fee, onChanged, parentDialog }) => {
    const title = 'Modifica remunerazione';

    const api = useApi();
    const ui = useUI();
    
    const [fee, setFee] = useState(_fee);

    const handleDelete = e => {
        const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='trash'
				title="Conferma rimozione"
				message="Confermi di volere rimuovere questo elemento?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
                        prompt.dismiss();

						api.fees.delete(fee.id).then(res => {
                            onChanged();
                            parentDialog.dismiss();
                        });                
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
    };

    const handleSubmit = e => {
        api.fees.patch(fee.id, fee).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message={res.message}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e=> parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 1.4rem' }}>
                <Form.Control label='Etichetta' errors={api.errors?.title}>
                    <Form.InputLabel>
                        <Icon name='sack-dollar' />
                        <input
                            onChange={e => setFee({...fee, title: e.target.value})}
                            placeholder='Esempio: Commissione inclusa nel premio'
                            value={fee.title} />
                    </Form.InputLabel>
                </Form.Control>
                <hr/>
                <Form.Control label='Nota / Riferimento normativo' errors={api.errors?.text}>
                    <Form.InputLabel>
                        <Icon name='pen' />
                        <textarea
                            onChange={e => setFee({...fee, text: e.target.value})}
                            placeholder='Esempio: "Onorario corrisposto direttamente dal cliente"'
                            rows={4}
                            value={fee.text} />
                    </Form.InputLabel>
                    <Form.TextLength text={fee.text} max={500} />
                    <Form.Tip text='Inserisci il testo da mostrare nei documenti' />
                </Form.Control>
                <hr/>
                <Form.Control label='Richiede somma o formula?' errors={api.errors?.has_amount}>
                    <Form.ButtonGroup>
                        <Form.ButtonGroup.Item
                            onChange={e => setFee({...fee, has_amount: true})}
                            name='has_amount'
                            label='Sì'
                            positive
                            selected={fee.has_amount}
                            value={true} />
                        <Form.ButtonGroup.Item
                            onChange={e => setFee({...fee, has_amount: false})}
                            label='No'
                            name='has_amount'
                            negative
                            selected={!fee.has_amount}
                            value={false}
                        />
                    </Form.ButtonGroup>
                    <Form.Tip text="In fase di invio degli allegati verrà chiesto di indicare la somma percepita oppure la formula utilizzata per calcolarla" />
                </Form.Control>
            </Form>
        </main>
        <footer>
            <button className='negative-link' onClick={handleDelete}>
                <span>elimina</span>
                <Icon name='trash' />
            </button>
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma</span>
            </button>
        </footer>
        <Loading show={api.loading} />
    </>
};

export default Patch;
