import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import ConsultingService from '../../models/ConsultingService';
import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Prompt from '../../widgets/Prompt';
import useUI from '../../hooks/useUI';
import useApi from '../../hooks/useApi';

const Post = ({ onChanged, parentDialog }) => {
    const title = 'Aggiungi consulenza';

    const api = useApi();
    const ui = useUI();
    
    const [service, setService] = useState(ConsultingService);

    const handleSubmit = e => {
        api.consultingServices.post(service).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Consulenza aggiunta'
                   /*  positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} */
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);

            
        });
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e=> parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Form onSubmit={e => e.preventDefault()} style={{ padding: '2rem 1.4rem' }}>
                <Form.Control label='Etichetta' errors={api.errors?.title}>
                    <Form.InputLabel>
                        <Icon name='briefcase' />
                        <input
                            onChange={e => setService({...service, title: e.target.value})}
                            placeholder='Esempio: Consulenza obbligatoria'
                            value={service.title} />
                    </Form.InputLabel>
                </Form.Control>
                <hr/>
                <Form.Control label='Nota / Riferimento normativo' errors={api.errors?.text}>
                    <Form.InputLabel>
                        <Icon name='pen' />
                        <textarea
                            onChange={e => setService({...service, text: e.target.value})}
                            placeholder='Esempio: "Fornisce una consulenza obbligatoria e gratuita ai sensi dell’art. 121-septies, Cap"'
                            rows={8}
                            value={service.text} />
                    </Form.InputLabel>
                    <Form.TextLength text={service.text} max={500} />
                    <Form.Tip text='Inserisci il testo da mostrare nei documenti' />
                </Form.Control>
            </Form>
        </main>
        <footer>
            <>&nbsp;</>
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma</span>
            </button>
        </footer>
        <Loading show={api.loading} />
    </>
};

export default Post;
