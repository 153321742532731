import React from 'react';
import useMath from '../hooks/useMath';
import './scss/UserRow.scss';

const UserRow = ({ name, url }) => {
    const math = useMath();

    const parts = name.split(" ");
    const initial1 = parts[0] ? parts[0][0] : "";
    const initial2 = parts[1] ? parts[1][0] : (parts[0][1] ? parts[0][1] : '');

    const colors = [
        '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#34495e',
        '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
        '#f1c40f', '#e67e22', '#e74c3c',
        '#f39c12', '#d35400', '#c0392b',
    ];

    //const color = math.interpolate(name.length, [0, 30], [colors.length, 0]);
    const color = 9;

    return <>
        <div className='userRow'>
            {url
                ? <img src={url} />
                : <span className='initials' style={{ background: colors[parseInt(color)] }}>
                    {initial1}
                    {initial2}
                </span>
            }
            <span className='name'>{name}</span>
        </div>
    </>
};

export default UserRow;