const fees = client => {
    return {
        index: () => client.get('/api/fees'),
        post: data => client.post(`/api/fees`, data),
        get: id => client.get(`/api/fees/${id}`),
        patch: (id, data) => client.post(`/api/fees/${id}/patch`, data),
        delete: id => client.post(`/api/fees/${id}/delete`)
    };
};

export default fees;
