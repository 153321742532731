import React from 'react';
import './scss/Cards.scss';

const Cards = ({ children }) => {
    return <>
        <div className='cards'>
            {children}
        </div>
    </>
};

const Card = ({ children, disabled, onClick }) => {
    return <>
        <div style={{ opacity: disabled ? .4 : 1 }} role='button' className='card' onClick={() => onClick()}>
            {children}
        </div>
    </>
};

export default Cards;
export { Card };
