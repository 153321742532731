import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Cards, { Card } from '../../widgets/Cards';
import Dialog from '../../widgets/Dialog';
import Error from '../../widgets/Error';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Patch from './Patch';
import PatchNote from './PatchNote';
import Post from './Post';
import useApi from '../../hooks/useApi';
import useBrowse, { BrowseProvider } from '../../hooks/useBrowse';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';

import './scss/Index.scss';

const List = () => {
    const title = 'Consulenze';

    const api = useApi();
    const browse = useBrowse();
    const date = useDate();
    const ui = useUI();

    const [note, setNote] = useState();
    const [rows, setRows] = useState([]);

    const fetch = () => {
        setNote();
        setRows([]);

        api.consultingServices.index().then(res => {
            setNote(res.data.note);
            setRows(res.data.rows);
        });
    };

    useEffect(() => {
        ui.setBreadcrumbs([
            { label: 'Home', path: '/' },
            { label: title },
        ])
    }, []);

    useEffect(() => {
        fetch();
    }, [browse.params]);

    const handleChanged = () => {
        fetch();
    };

    const handlePatch = _service => {
        if (!note) {
            return;
        }

        ui.show(<Dialog width='44rem'>
            <Patch id={_service.id} _service={_service} onChanged={handleChanged} />
        </Dialog>);
    };

    const handlePatchNote = e => {
        ui.show(<Dialog width='44rem'>
            <PatchNote _note={note?.value} onChanged={handleChanged} />
        </Dialog>);
    };

    const handlePost = e => {
        if (!note) {
            return;
        }

        ui.show(<Dialog width='40rem'>
            <Post onChanged={handleChanged} />
        </Dialog>);
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div data-component='companiesIndex'>
            <header>
                <h1>{title}</h1>
            </header>
            <section>
                <h2>Consulenze</h2>
                {/* {!api.loading && !api.error && <h2>Tutte<span>{rows.length} elementi</span></h2>} */}
                <div style={{ position: 'relative', height: '50vh' }}>
                    {!api.loading && !api.error && <>
                        <Cards>
                            <div aria-disabled={!note} role='button' className='placeholder' onClick={handlePost}>
                                <span>Aggiungi<Icon name='plus'/></span>
                            </div>
                            <Card onClick={() => handlePatchNote()}>
                                <header>
                                    <Icon name='pen'/>
                                    {!note
                                        ? <span><Icon name='triangle-exclamation' className='negative' /></span>
                                        : <span><Icon name='lock' /></span>
                                    }
                                </header>
                                <footer>
                                    <h3>Nessuna consulenza</h3>
                                    <div className='info'>
                                        {!note 
                                            ? <div><span>Nota non configurata</span></div>
                                            : <>
                                                <div>
                                                    <span>Modifica</span>
                                                    <span>{date.locale(note.updated_at)}</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </footer>
                            </Card>
                            {note && rows.map((row, index) =>
                                <Card key={index} onClick={() => handlePatch(row)}>
                                    <header>
                                    <Icon name='bullseye-pointer' />
                                    </header>
                                    <footer>
                                        <h3>{row.title}</h3>
                                        <div className='info'>
                                            <div>
                                                <span>Modifica</span>
                                                <span>{date.locale(row.updated_at)}</span>
                                            </div>
                                        </div>
                                    </footer>
                                </Card>
                            )}
                        </Cards>
                    </>}
                    <Loading show={api.loading} />
                    {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
                </div>
            </section>
        </div>
    </>
};

const Index = () => {
    return <>
        <BrowseProvider>
            <List />
        </BrowseProvider>
    </>
};

export default Index;
