import React from 'react';
import QRCode from 'react-qr-code';
import Icon from '../../widgets/Icon';

const SignaturePreview = ({ document, parentDialog }) => {
    return <>
        <header>
            <span>Firma</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <QRCode /*fgColor='#991b1d'*/ size={256} value={JSON.stringify({
                checksum: document.sign.document_sum,
                data: document.sign.data,
                timestamp: document.sign.created_at,
            })} /> 
        </main>
    </>
};

export default SignaturePreview;
