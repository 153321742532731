import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import useUI from '../hooks/useUI';
import './scss/Dropdown.scss';

const Dropdown = ({ 
	hash,
	trigger,
	align,
	justify,
	exiting,
	width='auto',
	height='auto', 
	children }) => {
	const ui = useUI();

	const [x, setX] = useState(0);
	const [y, setY] = useState(0);

	const ref = useRef();

	useEffect(() => {
		update();

		window.addEventListener('resize', update, true);
		window.addEventListener('scroll', update, true);

		return () => {
			window.removeEventListener('resize', update);
			window.removeEventListener('scroll', update);
		};
	}, [children]);

	const dismiss = () => {
		ui.dismiss(hash);
	};

	const update = e => {
		if (!ref.current) {
			return;
		}

		const triggerElement = trigger;
		const triggerRect = triggerElement.getBoundingClientRect();
		const dropdownElement = ref.current;
		const dropdownRect = dropdownElement.getBoundingClientRect();

		//console.log('triggerRect', triggerRect)
		//console.log('dropdownRect', dropdownRect)

		let x = 0;
		let y = 0;

		const gap = 14;

		const _width = width || dropdownRect.width;

		//console.log('width', _width);

		switch (align) {
			case 'top': {
				y = triggerRect.top - (dropdownRect.height);
				break;
			}
			case 'center': {
				y = (triggerRect.bottom - (triggerRect.height) / 2) - (dropdownRect.height / 2);
				break;
			}
			case 'bottom': {
				y = triggerRect.bottom;
				break;
			}
		}

		switch (justify) {
			case 'left': {
				x = triggerRect.right - dropdownRect.width;
				break;
			}
			case 'center': {
				x = (triggerRect.right - (triggerRect.width) / 2) - (dropdownRect.width / 2);
				break;
			}
			case 'right': {
				x = triggerRect.left;
				break;
			}
		}

		//console.log('dropdownPosition', x, y)

		setX(x);
		setY(y);
	};

	const handleClick = e => {
		if (!ref.current || ref.current.contains(e.target)) {
			return;
		}

		dismiss();
	};

	const dropdownStyle = {
		left: x,
		top: y,
		width,
		height
	};

	if (!children) {
		return <></>
	}

	return <>
		<Transition 
			appear={true}
			in={!exiting}
			timeout={{
				appear: 0,
				enter: 250,
				exit: 250
			}}
			unmountOnExit>
			{state => {
				const clazzes = [
					'dropdownBackdrop',
					state
				];

				return <>
					<div className={clazzes.join(' ')} onClick={handleClick}>
						<div ref={ref} style={dropdownStyle}>
							{React.Children.map(children, child => {
								return cloneElement(child, {
									parentDropdown: {
										dismiss,
										update
										//setWidth
									}
								})
							})}
						</div>
					</div>
				</>
			}}
		</Transition>
	</>
};

export default Dropdown;
