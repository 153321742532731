import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, Outlet, Navigate, useSearchParams } from 'react-router-dom';
import Forbidden from '../components/Auth/Forbidden';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const data = useAuthProvider();

    return (
        <AuthContext.Provider value={data}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuthProvider = () => {
    const [token, setToken] = useState();
    const [user, setUser] = useState();

    /* useEffect(() => {
        console.log('token', token);
        console.log('user', user);
    }, [token, user]); */

    const clear = () => {
        refresh();
    };

    const hasPermissionTo = permission => {
        return user.acl.permissions.includes(permission);
    };

    const is = role => {
        return user.acl.roles.includes(role);
    };

    const refresh = data => {
        setToken(data?.token);
        setUser(data?.user);
    };

    return {
        token,
        user,
        clear,
        hasPermissionTo,
        is,
        refresh
    }
};

const useAuth = () => {
    return useContext(AuthContext);
};

const AuthRoute = () => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.user) {
        return <Outlet />
    }

    // Reindirizza alla pagina di autenticazione, ma salva la location attuale
    // Questo permette di reindirizzare alla location richiesta dopo l'autenticazione
    if (!['/login', '/logout'].includes(location.pathname)) {
        return <Navigate to={`/login?next=${location.pathname}`} replace />
    }

    return <Navigate to='/login' replace />
};

const ForbiddenRoute = ({ except, children }) => {
    const auth = useAuth();

    if (auth.hasPermissionTo(except)) {
        return children;
    }

    return <Forbidden />
};

const GuestRoute = () => {
    const auth = useAuth();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    if (!auth.user) {
        return <Outlet />
    }

    const next = searchParams.get('next');

    if (location.pathname == '/login' && next !== null) {
        return <Navigate to={next} replace />
    }

    return <Navigate to='/' replace />
};

export default useAuth;
export { AuthProvider, AuthRoute, ForbiddenRoute, GuestRoute };
