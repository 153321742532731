import React from 'react';

import Alert from '../../widgets/Alert';
import Dialog from '../../widgets/Dialog';
import Icon from '../../widgets/Icon';
import Patch from '../PrivacyNotesQuestions/Patch';
import Post from '../PrivacyNotesQuestions/Post';
import useUI from '../../hooks/useUI';
import './scss/Questions.scss';

const Question = ({ question, onClick }) => {
    return <>
        <div className='question' role='button' onClick={onClick}>
            <span className='letter'>{question.letter || 'LETTERA'}</span>
            <div>
                <span className='title'>{question.name}</span>
                {question.is_mandatory ? <span className='mandatory'>Consenso obbligatorio</span> : undefined}
            </div>
        </div>
    </>
};

const Questions = ({ onChanged, privacyNote }) => {
    console.log('Questions.privacyNote', privacyNote)
    const ui = useUI();

    const handleQuestionPatch = question => {
        if (privacyNote.template.locked) {
            return;
        }

        ui.show(<Dialog width='48rem'>
            <Patch _question={question} privacyNote={privacyNote} onChanged={onChanged} />
        </Dialog>);
    };

    const handleQuestionPost = e => {
        if (privacyNote.template.locked) {
            return;
        }
        
        ui.show(<Dialog width='48rem'>
            <Post privacyNote={privacyNote} onChanged={onChanged} />
        </Dialog>);
    };

    return <>
        <header>
            <span className='title'>Quesiti associati</span>
            {privacyNote.questions.length > 0 && 
                <span className='count'>{privacyNote.questions.length}</span>
            }
        </header>
        {privacyNote.template.locked && <>
            <Alert>
                <Icon name='lock' />
                <span><mark>Informativa di sistema</mark><br/>Le informative di sistema non possono essere modificate</span>
            </Alert>
        </>}
        <div className='questions'>
            {!privacyNote.template.locked && <>
                <Alert show={!privacyNote.questions.length} mode='error'>
                    <Icon name='triangle-exclamation negative' />
                    <span><mark>Quesiti mancanti</mark><br/>Aggiungi dei quesiti a questa informativa.<br />Le informative prive di quesiti non potranno essere utilizzate</span>
                </Alert>
            </>}
            {privacyNote.questions.map((question, index) =>
                <Question
                    key={index}
                    onClick={e => handleQuestionPatch(question)}
                    question={question} />
                )
            }
        </div>
        <button disabled={privacyNote.template.locked} className='accented-2' onClick={handleQuestionPost}>
            <span>Aggiungi quesito</span>
            <Icon name='plus' />
        </button>
    </>
};

export default Questions;
