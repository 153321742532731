import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import Dialog from '../../widgets/Dialog';
import Form from '../../widgets/Form';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Panel from '../../widgets/Panel';
import PatchText from './PatchText';
import Prompt from '../../widgets/Prompt';
import Template from '../../models/Template';
import useApi from '../../hooks/useApi';
import useUI from '../../hooks/useUI';

const Patch = ({ id, onChanged, parentDialog }) => {
    const title = 'Modifica allegato';

    const api = useApi();
    const ui = useUI();

    const [template, setTemplate] = useState(Template);

    useEffect(() => {
        api.attachments.get(id).then(res => setTemplate(res.data));
    }, []);

    const handleDelete = e => {
        const prompt = <Dialog>
			<Prompt
				icon={<Icon name='signature' />}
                color='trash'
				title="Conferma rimozione"
				message="Confermi di volere rimuovere questo elemento?"
				positive={{
					label: "Conferma",
					onClick: prompt => {
                        prompt.dismiss();
                        
						api.attachments.delete(id).then(res => {
                            onChanged();
                            parentDialog.dismiss();
                        });
					}
				}}
				neutral={{
					label: "Annulla",
					onClick: prompt => {
						prompt.dismiss();
					}
				}}
			/>
		</Dialog>

		ui.show(prompt);
    };
    
    const handleTextPatch = () => {
        ui.show(<Dialog width='80rem' height='100vh'>
            <PatchText id={id} onChanged={onChanged} />
        </Dialog>);
    };

    const handleSubmit = e => {
        api.attachments.patch(id, template).then(res => {
            onChanged();

            ui.show(<Dialog>
                <Prompt
                    icon={<Icon name='check' />}
                    color='positive'
                    title='Fatto'
                    message='Allegato aggiornato'
                    positive={{ label: 'Continua le modifiche', onClick: prompt => {
                        prompt.dismiss();
                    }}}
                    neutral={{ label: 'Chiudi', onClick: prompt => {
                        prompt.dismiss();
                        parentDialog.dismiss();
                    }}} />
            </Dialog>);
        });
    };

    console.log('template', template)

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <span>{title}</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <Panel>
                <Panel.Main>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control label='Tipo' errors={api.errors?.id}>
                            <Form.InputLabel>
                                <Icon name='paperclip' />
                                <input disabled defaultValue={template.title}/>
                            </Form.InputLabel>
                        </Form.Control>
                        <hr/>
                        <Form.Control label='Pubblicato' errors={api.errors?.published}>
                            <Form.ButtonGroup>
                                <Form.ButtonGroup.Item
                                    onChange={e => setTemplate({...template, published: true})}
                                    name='published'
                                    label='Sì'
                                    positive
                                    selected={template.published}
                                    value={true} />
                                <Form.ButtonGroup.Item
                                    onChange={e => setTemplate({...template, published: false})}
                                    label='No'
                                    name='published'
                                    negative
                                    selected={!template.published}
                                    value={false}
                                />
                            </Form.ButtonGroup>
                            <Form.Tip text="Un modello non pubblicato non è disponibile per l'invio ai clienti" />
                        </Form.Control>
                        <hr/>
                        <Form.Control label='Contenuto'>
                            <button type='button' className='accented-2' onClick={handleTextPatch}>
                                <span>Contenuto</span>
                                <Icon name='pen' />
                            </button>
                        </Form.Control>
                    </Form>
                </Panel.Main>
            </Panel>
        </main>
        <footer>
            <button className='negative-link' onClick={handleDelete}>
                <span>elimina</span>
                <Icon name='trash' />
            </button>
            <button className='accented' onClick={handleSubmit}>
                <span>Conferma modifiche</span>
            </button>
        </footer>
        <Loading show={api.loading} opaque={!template.id} />
    </>
};

export default Patch;
