const consultingServices = client => {
    return {
        index: () => client.get('/api/consulting-services'),
        post: data => client.post('/api/consulting-services', data),
        patch: (id, data) => client.post(`/api/consulting-services/${id}/patch`, data),
        delete: id => client.post(`/api/consulting-services/${id}/delete`),
        patchNote: note => client.post('/api/consulting-services/patch-note', { note })
    };
};

export default consultingServices;
